import { Flex, Select, SimpleGrid } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SvgCalendar from "../../components/Icons/Calendar.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import Table from "../../components/Table/Table.tsx";
import { SortDirection } from "../../components/Table/types.ts";
import Title from "../../components/Title/Title.tsx";
import {
  AiPromptResultFilter,
  AiPromptResultOrderBy,
  AiPromptStatusFilterOptions,
  OrderBy,
} from "../../graphql/generated.ts";
import { useAIPrompts } from "../../hooks/api/ai/prompt/useAIPrompts.ts";
import { useAIPromptResults } from "../../hooks/api/ai/useAIPromptResults.ts";
import { useCompanyOptions } from "../../hooks/api/customer/useCompanyOptions.ts";
import { getCompanyOptions } from "../../utils/company.ts";
import { getPagesCount } from "../../utils/pagination.ts";
import { useAIPromptResultsTableData } from "./hooks/useAIPromptResultsTableData.tsx";
import { AIPromptResultFiltersValue } from "./types.ts";
import {
  getAIPromptResultApiFilter,
  getAIPromptResultApiSort,
} from "./utils.ts";

export const AIResultPage = () => {
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<AiPromptResultFilter>({});
  const [orderBy, setOrderBy] = useState<AiPromptResultOrderBy>({
    createdAt: OrderBy.Desc,
  });

  const [{ data: customersResponse, fetching: fetchingCustomers }] =
    useCompanyOptions();
  const customers = customersResponse?.customers.data;

  const [{ data: promptsResponse, fetching: fetchingPrompts }] = useAIPrompts({
    filter: { status: { equals: AiPromptStatusFilterOptions.Active } },
    orderBy: {
      createdAt: OrderBy.Desc,
    },
  });
  const prompts = promptsResponse?.prompts || [];
  const promptsOptions = prompts.map((p) => ({
    value: String(p.id),
    label: p.title,
  }));

  const [{ data: promptResultsResponse, fetching: fetchingPromptResults }] =
    useAIPromptResults({
      page,
      orderBy,
      filter,
    });
  const promptResults = promptResultsResponse?.promptResults.data || [];

  const pageCount = getPagesCount(promptResultsResponse?.promptResults?.total);

  const [columns, rows] = useAIPromptResultsTableData({
    data: promptResults,
  });

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleFilterChange = useCallback(
    (key: keyof AIPromptResultFiltersValue, value: unknown) => {
      setFilter(getAIPromptResultApiFilter({ [key]: value }));
    },
    [setFilter]
  );

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getAIPromptResultApiSort(sort));
    },
    [setOrderBy]
  );

  return (
    <>
      <Layout>
        <Preloader loading={fetchingCustomers || fetchingPrompts} />
        <Flex direction="column" gap={{ base: 16, sm: 24 }}>
          <Flex direction="column" gap={16}>
            <Title size="h1">AI Result Database</Title>
            <SimpleGrid
              cols={4}
              breakpoints={[
                { maxWidth: "sm", cols: 1 },
                { maxWidth: "lg", cols: 3 },
              ]}
              spacing={20}
              verticalSpacing={8}
            >
              <Select
                required
                searchable
                clearable
                size="m"
                placeholder="Select Customer Name"
                data={getCompanyOptions(customers)}
                onChange={(value) => handleFilterChange("customer", value)}
              />
              <Select
                required
                searchable
                clearable
                size="m"
                placeholder="Select Prompt"
                data={promptsOptions}
                onChange={(value) => handleFilterChange("prompt", value)}
              />
              <DatePickerInput
                placeholder="Select Run Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                type="range"
                size="m"
                clearable
                onChange={(value) => handleFilterChange("runDate", value)}
              />
            </SimpleGrid>
          </Flex>
          <Table
            columns={columns}
            rows={rows}
            verticalAlignment="top"
            defaultSort={{
              columnAccessor: "createdAt",
              direction: SortDirection.desc,
            }}
            loading={fetchingPromptResults}
            pagination={{
              pageCount: pageCount,
              page: page,
              onPageChange: handlePageChange,
            }}
            onSortChange={handleSortChange}
          />
        </Flex>
      </Layout>
    </>
  );
};
