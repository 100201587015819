import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../../components/Table/types";
import { LimitRequest } from "../../../../types/limitRequest/limitRequest.ts";
import { toLocalDate } from "../../../../utils/date";
import { toLocalNumber, toPrice } from "../../../../utils/number";
import { toHumanReadable } from "../../../../utils/string";
import { getUserDisplayName } from "../../../../utils/user.ts";

interface Props {
  data: LimitRequest[] | null | undefined;
}

export const useOngoingRRTableData = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "rrAssignedDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "rrAssignedDate",
          }}
        >
          RR Assigned Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitName",
          }}
        >
          Limit Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "companyName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "name",
          }}
        >
          Company Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "salesName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesName",
          }}
        >
          Sales Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesTeamName",
          }}
        >
          Sales Team
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },

    {
      accessor: "cuLimitRecommendation",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CreditUnderwriting",
            fieldName: "limitRecommendation",
          }}
        >
          CU Limit Recommendation
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "cuTenorRecommendation",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CreditUnderwriting",
            fieldName: "tenorRecommendation",
          }}
        >
          CU Tenor Recommendation
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "cuInsuranceLimit",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CreditUnderwriting",
            fieldName: "insuranceLimitApproved",
          }}
        >
          Insurance Limit
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitRequestType",
          }}
        >
          Limit Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "cuAssignedName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "cuAssignedId",
          }}
        >
          CU Assigned
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "rrAssignedName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "rrAssignedId",
          }}
        >
          RR Assigned
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "requestStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "requestStatus",
          }}
        >
          RR Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        rrAssignedDate:
          item?.rrAssignedDate && toLocalDate(item.rrAssignedDate),
        limitName: item.limitName,
        companyName: item.company.name,
        salesName: item.company.salesName,
        salesTeamName: item.company.salesTeamName,
        cuLimitRecommendation: toPrice(
          item?.creditUnderwriting?.limitRecommendation
        ),
        cuTenorRecommendation: toLocalNumber(
          item?.creditUnderwriting?.tenorRecommendation
        ),
        cuInsuranceLimit: toPrice(
          item?.creditUnderwriting?.insuranceLimitApproved
        ),
        limitType:
          item?.limitRequestType && toHumanReadable(item.limitRequestType),
        cuAssignedName: item?.cuAssigned && getUserDisplayName(item.cuAssigned),
        rrAssignedName: item.rrAssigned && getUserDisplayName(item.rrAssigned),
        requestStatus: toHumanReadable(item.requestStatus),
      };
    });
  }, [data]);

  return [columns, rows];
};
