import { Flex } from "@mantine/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import InvoicesTable from "../../../components/Invoice/InvoicesTable/InvoicesTable.tsx";
import Preloader from "../../../components/Preloader/Preloader.tsx";
import { InvoiceFilter } from "../../../graphql/generated";
import { useInvoice } from "../../../hooks/api/invoice/useInvoice.ts";
import { Invoice } from "../../../types/invoice/invoice.ts";
import InvoiceDetailTab from "../InvoiceDetailTab/InvoiceDetailTab.tsx";
import InvoicesFilterToolbar from "../InvoicesFilterToolbar/InvoicesFilterToolbar.tsx";
import { FiltersValue } from "../InvoicesFilterToolbar/types.ts";
import { useStyles } from "./InvoicesTab.styles.ts";
import { getInvoiceApiFilter } from "./utils.ts";

const InvoicesTab = () => {
  const { classes } = useStyles();
  const { customerId } = useParams() as { customerId: string };

  const [searchParams] = useSearchParams();
  const invoiceIdParam = searchParams.get("invoiceId");
  const invoiceId = invoiceIdParam ? Number(invoiceIdParam) : null;

  const [{ data: invoiceData, fetching: loadingInvoice }] = useInvoice({
    id: invoiceId,
  });

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>();

  const customerFilter = useMemo((): InvoiceFilter => {
    return { customerId: { equals: Number(customerId) } };
  }, [customerId]);
  const [filter, setFilter] = useState<InvoiceFilter>(customerFilter);

  const handleFilterChange = useCallback(
    (value: FiltersValue) => {
      setFilter({
        ...getInvoiceApiFilter(value),
        ...customerFilter,
      });
    },
    [customerFilter]
  );

  useEffect(() => {
    if (invoiceData) {
      setSelectedInvoice(invoiceData.invoice);
    }
  }, [invoiceData]);

  return (
    <>
      <Preloader loading={loadingInvoice} />
      {selectedInvoice ? (
        <InvoiceDetailTab
          data={selectedInvoice}
          onBreadcrumbsClick={() => {
            setSelectedInvoice(null);
          }}
        />
      ) : (
        <Flex
          direction="column"
          align="flex-start"
          gap={{ base: 14, sm: 20 }}
          className={classes.wrapper}
        >
          <InvoicesFilterToolbar onValueChange={handleFilterChange} />
          <InvoicesTable filter={filter} onClickRow={setSelectedInvoice} />
        </Flex>
      )}
    </>
  );
};

export default InvoicesTab;
