import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

interface Props {
  defaultTab: string;
  tabs: string[];
}

export function useTabFromUrl({ defaultTab, tabs }: Props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = useMemo(() => {
    const urlTab = searchParams.get("tab");
    if (urlTab && tabs.includes(urlTab)) {
      return urlTab;
    }
    return defaultTab;
  }, [defaultTab, searchParams, tabs]);

  useEffect(() => {
    if (searchParams.get("tab") !== tab) {
      setSearchParams({ tab });
    }
  }, [navigate, searchParams, setSearchParams, tab]);

  return tab;
}
