import { Button, Flex, MultiSelect, Select, SimpleGrid } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "../../components/Layout/Layout.tsx";
import Table from "../../components/Table/Table.tsx";
import { SortDirection, TableRow } from "../../components/Table/types.ts";
import Title from "../../components/Title/Title.tsx";
import { AppRoute } from "../../constants.ts";
import {
  ClikDataFilter,
  ClikDataOrderBy,
  OrderBy,
  RolePermission,
} from "../../graphql/generated.ts";
import { useClikDataList } from "../../hooks/api/clikData/useClikDataList.ts";
import { useSendClikDataMERequest } from "../../hooks/api/clikData/useSendClikDataMERequest.ts";
import { useCompanyOptions } from "../../hooks/api/customer/useCompanyOptions.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../providers/CurrentUserProvider.tsx";
import { ClikData } from "../../types/clikData.ts";
import { limitRequestTypeOptions } from "../../types/limitRequest/limitRequest.ts";
import { getCompanyOptions } from "../../utils/company.ts";
import { getPagesCount } from "../../utils/pagination.ts";
import { hasPermission } from "../../utils/user.ts";
import { useClikDataTable } from "./hooks/useClickDataTable.tsx";
import { FiltersValue } from "./types.ts";
import { geClikDataApiFilter, getClikDataApiSort } from "./utils.ts";

const ClikDataPage = () => {
  const { user: currentUser } = useCurrentUserContext();

  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<ClikDataFilter>({});
  const [orderBy, setOrderBy] = useState<ClikDataOrderBy>({
    createdAt: OrderBy.Desc,
  });

  const [{ data: clikDataResponse, fetching }, refreshClikData] =
    useClikDataList({
      filter,
      orderBy,
      page,
    });

  const [, sendClikDataMERequest] = useMutationNotificationWrapper(
    useSendClikDataMERequest(),
    {
      success: {
        message: "CLIK Data ME Request has been sent successfully.",
      },
    }
  );

  const clikData = clikDataResponse?.clikDataList?.data;

  const pageCount = getPagesCount(clikDataResponse?.clikDataList?.total);

  const handleMeRequest = (id: number) => {
    sendClikDataMERequest({ id }).then(refreshClikData);
  };

  const [columns, rows] = useClikDataTable({
    data: clikData,
    onMeRequest: handleMeRequest,
  });

  const [{ data: companies }] = useCompanyOptions();

  const hasAddAccess = hasPermission(
    currentUser,
    RolePermission.CanAddLimitRequest
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleFilterChange = useCallback(
    (key: keyof FiltersValue, value: string | string[]) => {
      setFilter(geClikDataApiFilter({ [key]: value }));
    },
    [setFilter]
  );

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getClikDataApiSort(sort));
    },
    [setOrderBy]
  );

  const handleNavigateToClikDataDetails = useCallback(
    (record: TableRow) => {
      if (!clikData) {
        return;
      }
      const clikItem = clikData.find((item: ClikData) => item.id === record.id);
      if (!clikItem) {
        return;
      }
      navigate(`${AppRoute.ClikData}/${clikItem.id}`);
    },
    [clikData, navigate]
  );

  const handleRequestClik = useCallback(() => {
    navigate(AppRoute.LimitRequestCreate);
  }, [navigate]);

  return (
    <>
      <Layout>
        <Flex direction="column" gap={{ base: 16, sm: 24 }}>
          <Flex direction="column" gap={16}>
            <Title size="h1">CLIK Data</Title>
            {hasAddAccess && (
              <Button
                uppercase
                variant="filledGolden"
                onClick={handleRequestClik}
              >
                Request CLIK
              </Button>
            )}
            <SimpleGrid
              cols={3}
              breakpoints={[
                { maxWidth: "sm", cols: 1 },
                { maxWidth: "lg", cols: 2 },
              ]}
              spacing={20}
              verticalSpacing={8}
            >
              <MultiSelect
                data={getCompanyOptions(companies?.customers.data)}
                label="Company Name"
                placeholder="Select Company Name"
                size="m"
                searchable
                clearable
                clearButtonProps={{ "aria-label": "Clear selection" }}
                onChange={(value: string[]) =>
                  handleFilterChange("companyName", value)
                }
              />
              <Select
                size="m"
                clearable
                label="Limit Request Type"
                placeholder="Select Limit Request Type"
                data={limitRequestTypeOptions}
                onChange={(value: string) =>
                  handleFilterChange("limitRequestType", value)
                }
              />
            </SimpleGrid>
          </Flex>
          <Table
            loading={fetching}
            columns={columns}
            rows={rows}
            defaultSort={{
              columnAccessor: "naeRequestDate",
              direction: SortDirection.asc,
            }}
            pagination={{
              pageCount: pageCount,
              page: page,
              onPageChange: handlePageChange,
            }}
            onSortChange={handleOnSortChange}
            onRowClick={handleNavigateToClikDataDetails}
          />
        </Flex>
      </Layout>
    </>
  );
};

export default ClikDataPage;
