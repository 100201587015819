import { Flex, Text } from "@mantine/core";
import {
  Dropzone as MantineDropzone,
  DropzoneProps as MantineDropzoneProps,
  PDF_MIME_TYPE,
} from "@mantine/dropzone";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { FileWithPath } from "react-dropzone";

import { FileResult } from "../../graphql/generated";
import { useUploadFiles } from "../../hooks/api/fileStorage/useUploadFiles.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import DownloadFile from "../DownloadFile/DownloadFile.tsx";
import SvgUploadFile from "../Icons/UploadFile.tsx";
import UploadFileError from "../Icons/UploadFileError.tsx";
import { maxSize } from "./constants.ts";
import { useStyles } from "./Dropzone.styles.ts";

export interface DropzoneProps
  extends Omit<MantineDropzoneProps, "accept" | "onDrop" | "children"> {
  value?: FileResult[] | null;
  subLabel?: string;
  allow?: boolean;
  maxFiles?: number;
  maxWidth?: number | string;
  accept?: string[];
  onUploadSuccess: (value: FileResult[]) => void;
  onRemove?: (link: string) => void;
}

export default function Dropzone({
  value,
  subLabel = "Only .pdf files up to 5MB are allowed",
  allow = true,
  maxFiles,
  maxWidth = 408,
  onUploadSuccess,
  onRemove,
  accept = PDF_MIME_TYPE,
  ...args
}: DropzoneProps) {
  const { classes } = useStyles({ maxWidth });

  const [, uploadFiles] = useMutationNotificationWrapper(useUploadFiles(), {
    success: { message: "File(s) are successfully uploaded." },
    loading: { message: "Please wait for file(s) to upload." },
  });

  const [error, setError] = useState<boolean>(false);

  const isMaxFiles = useMemo(
    () => !!(value && maxFiles && value?.length >= maxFiles),
    [value, maxFiles]
  );

  const handleUpload = useCallback(
    (input: FileWithPath[]) => {
      setError(false);
      uploadFiles({ input }).then((result) => {
        if (!result.error && !!result.data) {
          onUploadSuccess(result.data.uploadFiles);
        }
      });
    },
    [onUploadSuccess, uploadFiles]
  );

  return (
    <Flex
      direction="column"
      className={classNames(
        classes.wrapper,
        error && classes.error,
        !allow && classes.disabled
      )}
    >
      {!isMaxFiles && (
        <MantineDropzone
          accept={accept}
          maxSize={maxSize}
          activateOnClick={allow}
          className={classes.uploadWrapper}
          {...args}
          onDrop={handleUpload}
          onReject={() => setError(true)}
        >
          {!error && (
            <Flex gap={8}>
              <SvgUploadFile />
              <Flex direction="column">
                <Text className={classes.label}>
                  Drag document or click to select
                </Text>
                <Text className={classes.subLabel}>{subLabel}</Text>
              </Flex>
            </Flex>
          )}
          {error && (
            <Flex gap={8}>
              <UploadFileError />
              <Flex direction="column">
                <Text className={classes.label}>File wasn&apos;t uploaded</Text>
                <Text className={classes.subLabel}>{subLabel}</Text>
              </Flex>
            </Flex>
          )}
        </MantineDropzone>
      )}
      {value && (
        <Flex
          direction="column"
          gap={8}
          className={classNames(value.length > 0 && classes.uploadedFiles)}
        >
          {value.map((item, index) => (
            <DownloadFile key={index} file={item} onRemove={onRemove} />
          ))}
        </Flex>
      )}
    </Flex>
  );
}
