import { Box, Button, Flex, SimpleGrid } from "@mantine/core";
import { useState } from "react";

import MultiFieldCard, {
  MultiFieldCardProps,
} from "../Cards/MultiFieldCard/MultiFieldCard.tsx";
import { Loader } from "../Loader.tsx";
import NoDataMessage from "../NoDataMessage/NoDataMessage.tsx";

interface AccordionMultifieldCards {
  cardItems: Omit<MultiFieldCardProps, "loading">[] | null;
  loading: boolean;
  maxCount?: number;
}

const AccordionMultifieldsCards = ({
  cardItems,
  maxCount = 3,
  loading = false,
}: AccordionMultifieldCards) => {
  const [open, setOpen] = useState<boolean>();

  if (loading) {
    return (
      <Box w="100%" mih={400} p={20}>
        <Loader />
      </Box>
    );
  }

  if (!cardItems?.length) {
    return <NoDataMessage />;
  }

  const visibleItems =
    cardItems.length <= maxCount || open
      ? cardItems
      : cardItems.slice(0, maxCount);

  return (
    <Flex direction="column" gap={20} w="100%" align="center">
      <SimpleGrid
        w="100%"
        cols={3}
        breakpoints={[
          { maxWidth: "sm", cols: 1 },
          { maxWidth: "lg", cols: 2 },
        ]}
        spacing={20}
        verticalSpacing={16}
      >
        {visibleItems.map((item, index) => (
          <MultiFieldCard key={index} {...item} />
        ))}
      </SimpleGrid>
      {cardItems.length > maxCount && (
        <Button
          variant="outlineGolden"
          uppercase
          onClick={() => setOpen(!open)}
        >
          {open ? "show less" : "show more"}
        </Button>
      )}
    </Flex>
  );
};

export default AccordionMultifieldsCards;
