import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(
  (theme, { isOpen }: { isOpen: boolean }) => ({
    wrapper: {
      width: isOpen ? rem(300) : rem(84),
      background: `linear-gradient(357deg, ${theme.colors.dusk[0]} 8.1%, ${theme.colors.dusk[1]} 51.61%, ${theme.colors.dusk[2]} 64.51%, ${theme.colors.dusk[3]} 85.59%)`,
      padding: rem(20),
      transition: "all 100ms ease",
      zIndex: 1000,
    },
    itemsWrapper: {
      height: "100%",
    },
    divider: {
      borderColor: theme.colors.dusk[8],
    },
    logoutWrapper: {
      textDecoration: "none",
    },
  })
);
