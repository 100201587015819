import { pickBy } from "lodash";
import isEqual from "lodash/isEqual";

import { InvoiceFilter, StringFilterMode } from "../../graphql/generated";
import { IsRestructured } from "../../types/invoice/invoice.ts";
import { getDateTimeRangeFilter } from "../../utils/filter.ts";
import { FiltersValue } from "./types.ts";

const getInvoiceRestructuredApiFilter = (value: Array<string> | undefined) => {
  if (!value?.length) {
    return null;
  }
  if (isEqual(value, [IsRestructured.yes])) {
    return { equals: true };
  }
  if (isEqual(value, [IsRestructured.no])) {
    return { equals: false };
  }
  return null;
};

export const getInvoiceApiFilter = (value: FiltersValue): InvoiceFilter => {
  return pickBy({
    invoiceNumber: value.invoiceNumber && {
      contains: value.invoiceNumber,
      mode: StringFilterMode.Insensitive,
    },
    dpdBucket: value.dpdBucket?.length && { in: value.dpdBucket },
    customerId: value.companyName?.length && {
      in: value.companyName?.map((c: string) => Number(c)),
    },
    paymentStatus: value.paymentStatus?.length && { in: value.paymentStatus },
    paymentType: value.paymentType?.length && { in: value.paymentType },
    paymentMethod: value.paymentMethod?.length && { in: value.paymentMethod },
    invoiceDueDate: getDateTimeRangeFilter(value.invoiceDueDate),
    isRestructured: getInvoiceRestructuredApiFilter(value.isRestructured),
    collectorAssignedId: value.collector?.length && {
      in: value.collector?.map((c: string) => Number(c)),
    },
  });
};
