import pickBy from "lodash/pickBy";
import { DataTableSortStatus } from "mantine-datatable";

import {
  AiPromptResultFilter,
  AiPromptResultOrderBy,
} from "../../graphql/generated.ts";
import { getApiOrderBy } from "../../utils/api.ts";
import { getDateTimeRangeFilter } from "../../utils/filter.ts";
import { AIPromptResultFiltersValue } from "./types.ts";

export const getAIPromptResultApiFilter = (
  value: AIPromptResultFiltersValue
): AiPromptResultFilter => {
  return pickBy({
    customer: value.customer && { id: { equals: Number(value.customer) } },
    prompt: value.prompt && { id: { equals: Number(value.prompt) } },
    createdAt: value.runDate && getDateTimeRangeFilter(value.runDate),
  });
};

export const getAIPromptResultApiSort = (
  tableSort: DataTableSortStatus
): AiPromptResultOrderBy => {
  const { customer, prompt, createdBy, ...apiSort } = getApiOrderBy(tableSort);
  return {
    ...apiSort,
    customer: customer && { name: customer },
    prompt: prompt && { title: prompt },
    createdBy: createdBy && { name: createdBy },
  };
};
