import { Box } from "@mantine/core";
import classNames from "classnames";
import isNil from "lodash/isNil";
import {
  DataTable,
  DataTableColumn,
  DataTableSortStatus,
} from "mantine-datatable";
import { DataTableVerticalAlignment } from "mantine-datatable/dist/types";
import React, {
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from "react";

import SvgChevronUp from "../Icons/ChevronUp.tsx";
import SvgUnfold from "../Icons/Unfold.tsx";
import { Loader } from "../Loader.tsx";
import NoDataMessage from "../NoDataMessage/NoDataMessage.tsx";
import Pagination from "../Pagination/Pagination";
import { emptyTableSort, TableEmptyValue } from "./constants.tsx";
import { useStyles } from "./Table.styles.ts";
import { TableRow } from "./types.ts";

type TablePagination = {
  pageCount: number;
  page: number;
  onPageChange: (page: number) => void;
};

type TableExpanded = {
  recordIds: number[] | string[];
  onRecordIdsChange: Dispatch<SetStateAction<number[]>>;
};

type TableRowExpansion = {
  allowMultiple?: boolean;
  expanded: TableExpanded;
  content: (props: {
    record: TableRow;
    recordIndex: number;
    collapse: () => void;
  }) => ReactNode;
};

export interface TableProps {
  columns: DataTableColumn<TableRow>[];
  rows?: TableRow[];
  defaultSort?: DataTableSortStatus;
  minWidth?: CSSProperties["minWidth"];
  className?: string;
  pagination?: TablePagination;
  loading?: boolean;
  withBorder?: boolean;
  withColumnBorders?: boolean;
  variant?: "default" | "nested";
  mih?: number;
  height?: string | number;
  rowExpansion?: TableRowExpansion;
  verticalAlignment?: DataTableVerticalAlignment;
  pinLastColumn?: boolean;
  highlightOnHover?: boolean;
  noHeader?: boolean;
  onSortChange?: (sortStatus: DataTableSortStatus) => void;
  onRowClick?: (record: TableRow, index: number) => void;
}

export default function Table({
  columns,
  rows,
  defaultSort = emptyTableSort,
  minWidth,
  className,
  pagination,
  loading = false,
  withBorder = false,
  withColumnBorders = false,
  variant = "default",
  mih = 200,
  height,
  rowExpansion,
  verticalAlignment,
  pinLastColumn,
  highlightOnHover = true,
  noHeader,
  onSortChange,
  onRowClick,
}: TableProps) {
  const [sort, setSort] = useState<DataTableSortStatus>(defaultSort);

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setSort(sort);
      onSortChange && onSortChange(sort);
    },
    [onSortChange]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      pagination && pagination.onPageChange(page);
    },
    [pagination]
  );

  const { classes } = useStyles({ minWidth, variant });

  const getDefaultColumnRender = (
    record: TableRow,
    _index: number,
    key: string
  ) => {
    return <>{isNil(record[key]) ? TableEmptyValue : record[key]}</>;
  };

  if (!loading && !rows?.length) {
    return <NoDataMessage minWidth={minWidth} />;
  }

  return (
    <Box mih={mih} w="100%" pos="relative">
      <div className={classes.tableWrapper}>
        <DataTable<TableRow>
          mih={mih}
          borderColor="rgb(176, 182, 189)"
          rowBorderColor="rgb(176, 182, 189)"
          noHeader={noHeader}
          verticalSpacing="xs"
          horizontalSpacing="xs"
          sortIcons={{ sorted: <SvgChevronUp />, unsorted: <SvgUnfold /> }}
          highlightOnHover={highlightOnHover}
          records={rows}
          columns={columns}
          sortStatus={sort}
          withBorder={withBorder}
          withColumnBorders={withColumnBorders}
          onSortStatusChange={handleSortChange}
          className={classNames(classes.table, className)}
          rowExpansion={rowExpansion}
          verticalAlignment={verticalAlignment}
          noRecordsIcon={<React.Fragment />}
          pinLastColumn={pinLastColumn}
          height={height}
          fetching={loading}
          customLoader={<Loader />}
          defaultColumnRender={getDefaultColumnRender}
          scrollAreaProps={{ className: classes.scrollArea, type: "auto" }}
          onRowClick={onRowClick}
        />
        {pagination && (
          <Pagination
            total={pagination.pageCount}
            value={pagination.page}
            onChange={handlePageChange}
          />
        )}
      </div>
    </Box>
  );
}
