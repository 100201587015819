import { useMemo } from "react";
import { Link } from "react-router-dom";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { LimitRequestDetail } from "../../../../types/limitRequest/limitRequest.ts";
import { RiskReview } from "../../../../types/riskReview.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toCurrencyPrice, toPercent } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { getUserDisplayName } from "../../../../utils/user.ts";

interface Props {
  limitRequest:
    | Pick<LimitRequestDetail, "rrAssigned" | "rrAssignedDate">
    | null
    | undefined;
  riskReview: RiskReview | null | undefined;
}

export default function useRiskReviewCardData({
  limitRequest,
  riskReview,
}: Props) {
  return useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "rrAssignedDate",
            }}
            position="right"
          >
            RR Assigned Date
          </DatabaseMetadataPopover>
        ),
        content: toLocalDate(limitRequest?.rrAssignedDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "rrAssigned",
            }}
            position="right"
          >
            RR assigned
          </DatabaseMetadataPopover>
        ),
        content: getUserDisplayName(limitRequest?.rrAssigned),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "RiskReview",
              fieldName: "analysisResultDate",
            }}
            position="right"
          >
            RR analysis result date
          </DatabaseMetadataPopover>
        ),
        content: toLocalDate(riskReview?.analysisResultDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "RiskReview",
              fieldName: "analysisResult",
            }}
            position="right"
          >
            RR analysis result
          </DatabaseMetadataPopover>
        ),
        content: toHumanReadable(riskReview?.analysisResult),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "RiskReview",
              fieldName: "limitRecommendation",
            }}
            position="right"
          >
            RR Limit recommendation
          </DatabaseMetadataPopover>
        ),
        content: toCurrencyPrice(riskReview?.limitRecommendation),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "RiskReview",
              fieldName: "pricingRecommendation",
            }}
            position="right"
          >
            RR Pricing recommendation
          </DatabaseMetadataPopover>
        ),
        content: toPercent(riskReview?.pricingRecommendation, "percentage"),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "RiskReview",
              fieldName: "riskCategory",
            }}
            position="right"
          >
            Risk category
          </DatabaseMetadataPopover>
        ),
        content: riskReview?.riskCategory
          ? toHumanReadable(riskReview?.riskCategory)
          : riskReview
          ? "N/A"
          : null,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "RiskReview",
              fieldName: "tenorRecommendation",
            }}
            position="right"
          >
            RR Tenor Recommendation
          </DatabaseMetadataPopover>
        ),
        content: riskReview?.tenorRecommendation,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "RiskReview",
              fieldName: "notes",
            }}
            position="right"
          >
            RR notes
          </DatabaseMetadataPopover>
        ),
        content: riskReview?.notes,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "RiskReview",
              fieldName: "document",
            }}
            position="right"
          >
            RR document
          </DatabaseMetadataPopover>
        ),
        content: riskReview?.document && (
          <Link to={riskReview.document.url} target="_blank">
            Download
          </Link>
        ),
      },
    ];
  }, [riskReview, limitRequest]);
}
