import { SelectItem } from "@mantine/core/lib/Select/types";
import { useDisclosure } from "@mantine/hooks";
import { useCallback, useMemo } from "react";

import MultiFieldCard from "../../../components/Cards/MultiFieldCard/MultiFieldCard";
import ModalWithNotes from "../../../components/ModalWithNotes/ModalWithNotes.tsx";
import {
  LimitRequestStatus,
  RolePermission,
  UpdateRiskReviewInput,
} from "../../../graphql/generated";
import { useHandoverLimitRequestToCC } from "../../../hooks/api/limitRequest/useHandoverLimitRequestToCC";
import { useHandoverLimitRequestToCU } from "../../../hooks/api/limitRequest/useHandoverLimitRequestToCU";
import { useCreateRiskReview } from "../../../hooks/api/riskReview/useCreateRiskReview";
import { useRiskReview } from "../../../hooks/api/riskReview/useRiskReview";
import { useUpdateRiskReview } from "../../../hooks/api/riskReview/useUpdateRiskReview";
import { useRRAssignees } from "../../../hooks/api/user/useRRAssignees.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper";
import { useCurrentUserContext } from "../../../providers/CurrentUserProvider.tsx";
import { LimitRequestDetail } from "../../../types/limitRequest/limitRequest.ts";
import { getUserOptions, hasPermission } from "../../../utils/user.ts";
import LimitRequestDetailCardControl from "../LimitRequestDetailCardControl/LimitRequestDetailCardControl";
import useRiskReviewCardData from "./hooks/useRiskReviewCardData";
import useRiskReviewCardFooter from "./hooks/useRiskReviewCardFooter";
import RiskReviewModal from "./RiskReviewModal";

interface Props {
  limitRequest:
    | Pick<
        LimitRequestDetail,
        "id" | "riskReview" | "requestStatus" | "rrAssigned"
      >
    | null
    | undefined;
  onRefreshLimitRequest: () => void;
}

const RiskReviewCard = ({ limitRequest, onRefreshLimitRequest }: Props) => {
  const [{ data: riskReviewResponse, fetching }, refresh] = useRiskReview({
    id: limitRequest?.riskReview?.id,
  });
  const riskReview = riskReviewResponse?.riskReview;

  const { user } = useCurrentUserContext();

  const [{ data: assigneesData }] = useRRAssignees();
  const assigneesOptions: SelectItem[] = useMemo(() => {
    return getUserOptions(assigneesData?.users.data);
  }, [assigneesData]);

  const [
    riskReviewModalOpened,
    { open: riskReviewModalOpen, close: riskReviewModalClose },
  ] = useDisclosure(false);

  const [
    riskReviewHandoverModalOpened,
    { open: riskReviewHandoverModalOpen, close: riskReviewHandoverModalClose },
  ] = useDisclosure(false);

  const [, updateRiskReview] = useMutationNotificationWrapper(
    useUpdateRiskReview(),
    {
      success: { message: "Risk Review data was successfully updated." },
    }
  );

  const [, createRiskReview] = useMutationNotificationWrapper(
    useCreateRiskReview(),
    {
      success: { message: "Risk Review data was successfully created." },
    }
  );

  const [, handoverLimitRequestToCC] = useMutationNotificationWrapper(
    useHandoverLimitRequestToCC(),
    {
      success: {
        message:
          "Limit Request was successfully handovered to Credit Committee.",
      },
    }
  );

  const [, handoverLimitRequestToCU] = useMutationNotificationWrapper(
    useHandoverLimitRequestToCU(),
    {
      success: {
        message:
          "Limit Request was successfully handovered to Credit Underwriting.",
      },
    }
  );

  const hasEditAccess = hasPermission(
    user,
    RolePermission.CanEditBuyerInfoLimitInfoRiskReview
  );

  const handleEditRiskReview = useCallback(
    (assignedId: number, assignedDate: Date, input: UpdateRiskReviewInput) => {
      if (!limitRequest?.id) {
        return;
      }
      if (!riskReview?.id) {
        createRiskReview({
          assignedId,
          assignedDate,
          input: {
            ...input,
            limitRequestId: limitRequest.id,
          },
        })
          .then(() => onRefreshLimitRequest())
          .then(() => {
            refresh();
          });
        return;
      }
      updateRiskReview({
        id: riskReview.id,
        assignedId,
        assignedDate,
        input,
      }).then(() => {
        refresh();
      });
    },
    [
      refresh,
      onRefreshLimitRequest,
      riskReview,
      limitRequest,
      updateRiskReview,
      createRiskReview,
    ]
  );

  const handleHandoverCC = useCallback(() => {
    if (!riskReview || !limitRequest) {
      return;
    }
    handoverLimitRequestToCC({ id: limitRequest?.id }).then(() => {
      onRefreshLimitRequest();
    });
  }, [
    riskReview,
    limitRequest,
    handoverLimitRequestToCC,
    onRefreshLimitRequest,
  ]);

  const handleHandoverCU = useCallback(
    (note: string) => {
      if (!riskReview || !limitRequest) {
        return;
      }
      handoverLimitRequestToCU({
        id: limitRequest?.id,
        input: { text: note, riskReviewId: riskReview.id },
      }).then(() => {
        onRefreshLimitRequest();
      });
    },
    [riskReview, limitRequest, handoverLimitRequestToCU, onRefreshLimitRequest]
  );

  const riskReviewInfo = useRiskReviewCardData({ limitRequest, riskReview });

  const cardFooterContent = useRiskReviewCardFooter({
    limitRequestStatus: limitRequest?.requestStatus,
    canEdit: hasEditAccess,
    riskReview,
    onHandoverToCU: riskReviewHandoverModalOpen,
    onHandoverToCC: handleHandoverCC,
    onAdd: riskReviewModalOpen,
  });

  const cardControl = useMemo(() => {
    if (!riskReview) {
      return null;
    }
    return (
      <LimitRequestDetailCardControl
        updatedBy={riskReview?.updatedBy}
        edit={
          hasEditAccess &&
          riskReview &&
          limitRequest?.requestStatus === LimitRequestStatus.OngoingRr
        }
        onEdit={riskReviewModalOpen}
      />
    );
  }, [riskReview, hasEditAccess, limitRequest, riskReviewModalOpen]);

  return (
    <>
      <MultiFieldCard
        label="Risk Review"
        variant="blue"
        loading={fetching}
        items={riskReviewInfo}
        cardControl={cardControl}
        cardFooterContent={cardFooterContent}
        disabled={
          !riskReview &&
          limitRequest?.requestStatus !== LimitRequestStatus.OngoingRr
        }
      />
      {riskReviewModalOpened && (
        <RiskReviewModal
          value={riskReview}
          limitRequest={limitRequest}
          opened={true}
          assigneesOptions={assigneesOptions}
          onClose={riskReviewModalClose}
          onSubmit={handleEditRiskReview}
        />
      )}
      <ModalWithNotes
        title={"Handover to CU"}
        opened={riskReviewHandoverModalOpened}
        onClose={riskReviewHandoverModalClose}
        onSubmit={handleHandoverCU}
      />
    </>
  );
};

export default RiskReviewCard;
