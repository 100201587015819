import { MantineTheme, rem } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

const defaultCalendar = (theme: MantineTheme) => ({
  icon: {
    color: theme.colors.grayIce[0],
    justifyContent: "end",
    svg: {
      height: rem(24),
      width: rem(24),
    },
  },
  calendarHeader: {
    marginBottom: rem(20),
    maxWidth: "unset",
  },
  calendarHeaderControlIcon: {
    color: theme.colors.golden[0],
  },
  weekday: {
    paddingBottom: rem(8),
    color: theme.colors.grayIce[0],
  },
  day: {
    color: theme.colors.black[0],

    "&:hover": {
      background: theme.colors.gray[0],
    },

    "&[data-selected]": {
      color: theme.colors.black[0],
      background: theme.colors.golden[4],

      "&:hover": {
        background: theme.colors.golden[6],
      },
    },

    "&[data-in-range]": {
      background: theme.colors.golden[4],

      "&:hover": {
        background: theme.colors.golden[6],
      },
    },

    "&[data-weekend]": {
      color: theme.colors.black[0],
    },
  },
});

function defaultCalendarTheme() {
  return {
    styles: (theme: MantineTheme) => ({
      ...defaultCalendar(theme),
    }),
  };
}

export default function calendarTheme(): ThemeComponent {
  return {
    ...defaultCalendarTheme(),
  };
}
