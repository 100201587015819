import {
  Accordion,
  Button,
  Flex,
  MediaQuery,
  MultiSelect,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDebouncedState } from "@mantine/hooks";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import SvgCalendar from "../../components/Icons/Calendar.tsx";
import SvgUnfold from "../../components/Icons/Unfold.tsx";
import InvoicesTable from "../../components/Invoice/InvoicesTable/InvoicesTable.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Title from "../../components/Title/Title.tsx";
import { AppRoute } from "../../constants.ts";
import { RolePermission } from "../../graphql/generated";
import { useCompanyOptions } from "../../hooks/api/customer/useCompanyOptions.ts";
import { useCollectors } from "../../hooks/api/user/useCollectors.ts";
import { useCurrentUserContext } from "../../providers/CurrentUserProvider.tsx";
import {
  dpdBucketOptions,
  isRestructuredOptions,
  paymentMethodOptions,
  paymentStatusOptions,
  paymentTypeOptions,
} from "../../types/invoice/invoice.ts";
import { getCompanyOptions } from "../../utils/company.ts";
import { getDateRangeFilter, getFiltersCount } from "../../utils/filter.ts";
import { getUserOptions, hasPermission } from "../../utils/user.ts";
import { useStyles } from "./ActivityManagement.styles.ts";
import { FiltersValue } from "./types.ts";
import { getInvoiceApiFilter } from "./utils.ts";

export default function ActivityManagementPage() {
  const navigate = useNavigate();

  const { classes } = useStyles();

  const [invoiceNumber, setInvoiceNumber] = useDebouncedState("", 500);
  const [filter, setFilter] = useState<FiltersValue>({});
  const invoiceFilter = getInvoiceApiFilter({ ...filter, invoiceNumber });
  const lastPaymentDateFilter = getDateRangeFilter(filter.lastPaymentDate);

  const [{ data: collectorsData }] = useCollectors();
  const collectors = collectorsData?.users.data;

  const [{ data: customersData }] = useCompanyOptions();
  const customers = customersData?.customers.data;

  const { user } = useCurrentUserContext();

  const hasAccess = hasPermission(
    user,
    RolePermission.CanAssignInvoiceCollector
  );

  const isFilterApplied = useMemo(() => !!getFiltersCount(filter), [filter]);

  const handleFilterChange = useCallback((value: Partial<FiltersValue>) => {
    setFilter((prevFilter: FiltersValue) => ({
      ...prevFilter,
      ...value,
    }));
  }, []);

  const handleCleanFilters = useCallback(() => {
    setFilter({});
  }, []);

  return (
    <Layout>
      <Flex direction="column" align="flex-start" gap={{ base: 16, sm: 24 }}>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <Flex direction="column" gap={16} className={classes.wrapper}>
            <Flex justify="space-between">
              <Title>Activity Management</Title>
              {isFilterApplied && (
                <Button
                  variant="outlineGolden"
                  uppercase
                  onClick={handleCleanFilters}
                >
                  clear filters
                </Button>
              )}
            </Flex>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Accordion variant="goldenFilled" chevronPosition="left" multiple>
              <Accordion.Item value="customer" m={16}>
                <Accordion.Control>
                  <Text>Customer Filter</Text>
                </Accordion.Control>
                <Accordion.Panel p={16}>
                  <SimpleGrid
                    cols={3}
                    verticalSpacing={16}
                    breakpoints={[
                      { minWidth: "sm", cols: 2, verticalSpacing: 20 },
                      { minWidth: "lg", cols: 3, verticalSpacing: 20 },
                    ]}
                    spacing={20}
                  >
                    <MultiSelect
                      size="m"
                      data={getCompanyOptions(customers)}
                      label="Company Name"
                      placeholder="Select Company Name"
                      searchable
                      nothingFound="No results found"
                      rightSection={<SvgUnfold />}
                      onChange={(companyName) =>
                        handleFilterChange({ companyName })
                      }
                    />
                    <MultiSelect
                      size="m"
                      data={getUserOptions(collectors)}
                      label="Collector"
                      placeholder="Select Collector"
                      searchable
                      nothingFound="No results found"
                      rightSection={<SvgUnfold />}
                      onChange={(collector) =>
                        handleFilterChange({ collector })
                      }
                    />
                  </SimpleGrid>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="payment" m={16}>
                <Accordion.Control>
                  <Text>Payment Filter</Text>
                </Accordion.Control>
                <Accordion.Panel p={16}>
                  <SimpleGrid
                    cols={3}
                    verticalSpacing={16}
                    breakpoints={[
                      { minWidth: "sm", cols: 2, verticalSpacing: 20 },
                      { minWidth: "lg", cols: 3, verticalSpacing: 20 },
                    ]}
                    spacing={20}
                  >
                    <MultiSelect
                      size="m"
                      data={paymentStatusOptions}
                      label="Payment Status"
                      placeholder="Select Payment Status"
                      rightSection={<SvgUnfold />}
                      onChange={(paymentStatus) =>
                        handleFilterChange({ paymentStatus })
                      }
                    />
                    <MultiSelect
                      size="m"
                      data={paymentMethodOptions}
                      label="Payment Method"
                      placeholder="Select Payment Method"
                      rightSection={<SvgUnfold />}
                      onChange={(paymentMethod) =>
                        handleFilterChange({ paymentMethod })
                      }
                    />
                    <MultiSelect
                      size="m"
                      data={paymentTypeOptions}
                      label="Payment Type"
                      placeholder="Select Payment Type"
                      rightSection={<SvgUnfold />}
                      onChange={(paymentType) =>
                        handleFilterChange({ paymentType })
                      }
                    />
                    <MultiSelect
                      size="m"
                      data={isRestructuredOptions}
                      label="Is Restructured"
                      placeholder="Select Is Restructured"
                      rightSection={<SvgUnfold />}
                      onChange={(isRestructured) =>
                        handleFilterChange({ isRestructured })
                      }
                    />
                    <MultiSelect
                      size="m"
                      data={dpdBucketOptions}
                      label="DPD Bucket"
                      placeholder="Select DPD Bucket"
                      rightSection={<SvgUnfold />}
                      onChange={(dpdBucket) =>
                        handleFilterChange({ dpdBucket })
                      }
                    />
                    <TextInput
                      size="m"
                      label="Invoice No"
                      placeholder="Invoice No"
                      onChange={(event) => {
                        setInvoiceNumber(event.target.value);
                      }}
                    />
                  </SimpleGrid>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="date" m={16}>
                <Accordion.Control>
                  <Text>Date Filter</Text>
                </Accordion.Control>
                <Accordion.Panel p={16}>
                  <SimpleGrid
                    cols={3}
                    verticalSpacing={16}
                    breakpoints={[
                      { minWidth: "sm", cols: 2, verticalSpacing: 20 },
                      { minWidth: "lg", cols: 3, verticalSpacing: 20 },
                    ]}
                    spacing={20}
                  >
                    <DatePickerInput
                      label="Invoice Due Date"
                      placeholder="Select Invoice Due Date"
                      icon={<SvgCalendar />}
                      firstDayOfWeek={0}
                      type="range"
                      size="m"
                      clearable
                      onChange={(invoiceDueDate) =>
                        handleFilterChange({ invoiceDueDate })
                      }
                    />
                    <DatePickerInput
                      label="Last Payment Date"
                      placeholder="Select Last Payment Date"
                      icon={<SvgCalendar />}
                      firstDayOfWeek={0}
                      type="range"
                      size="m"
                      clearable
                      onChange={(lastPaymentDate) =>
                        handleFilterChange({ lastPaymentDate })
                      }
                    />
                  </SimpleGrid>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Flex>
        </MediaQuery>
        <InvoicesTable
          filter={invoiceFilter}
          lastPaymentDateFilter={lastPaymentDateFilter}
          canEdit={hasAccess}
          onClickRow={(invoice) => {
            navigate(
              `${AppRoute.ActivityManagement}/${invoice.customer.id}?tab=invoices&invoiceId=${invoice.id}`
            );
          }}
        />
      </Flex>
    </Layout>
  );
}
