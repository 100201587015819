import { Flex } from "@mantine/core";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import ActionIcon from "../../../components/ActionIcon/ActionIcon.tsx";
import Delete from "../../../components/Icons/Delete.tsx";
import Edit from "../../../components/Icons/Edit.tsx";
import { RawGroup } from "../../../types/limitRequest/group.ts";
import { useStyles } from "../LimitRequestCreate.styles.ts";

interface Props {
  groups: RawGroup[];
  onEdit: (value: RawGroup, index: number) => void;
  onDelete: (value: number) => void;
}

export const useGroupsInfo = ({ groups, onEdit, onDelete }: Props) => {
  const { classes } = useStyles();

  return useMemo(() => {
    return groups?.map((item, index) => ({
      label: item.name,
      cardControl: (
        <Flex gap={8}>
          <ActionIcon spaces={false} onClick={() => onEdit(item, index)}>
            <Edit />
          </ActionIcon>
          <ActionIcon
            variant="error"
            spaces={false}
            onClick={() => onDelete(index)}
          >
            <Delete />
          </ActionIcon>
        </Flex>
      ),
      items: [
        {
          label: "Group Name",
          content: item.name,
        },
        {
          label: "Bussiness Identify Number",
          content: item.bussinessIdentifyNumber,
        },
        {
          label: "Phone",
          content: item.phone,
        },
        {
          label: "NPWP File",
          content: (
            <Link
              className={classes.fileName}
              to={item.npwpFile.url}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </Link>
          ),
        },
        {
          label: "Registration Form",
          content: (
            <Link
              className={classes.fileName}
              to={item.registrationForm.url}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </Link>
          ),
        },
      ],
    }));
  }, [groups, classes.fileName, onEdit, onDelete]);
};
