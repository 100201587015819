import { sortBy } from "lodash";
import get from "lodash/get";

import {
  GetPaymastersQuery,
  PaymasterFinalScore,
  PaymasterHistoricalCreditControl,
  PaymasterHistoricalPayment,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";

export type Paymaster = GetPaymastersQuery["paymasters"]["data"][0];

export const toHumanReadablePaymasterHistoricalPayment = (
  value: PaymasterHistoricalPayment
): string => {
  const mapping: Record<PaymasterHistoricalPayment, string> = {
    [PaymasterHistoricalPayment.OnTime]: "On Time",
    [PaymasterHistoricalPayment.Delay_1_10Days]: "Delay 1-10 Days",
    [PaymasterHistoricalPayment.DelayMore_10Days]: "Delay more than 10 Days",
    [PaymasterHistoricalPayment.Npl]: "NPL",
    [PaymasterHistoricalPayment.TransactionCancelled]: "Transaction Canceled",
    [PaymasterHistoricalPayment.TransactionUnidentified]:
      "Transaction Unidentified",
    [PaymasterHistoricalPayment.Outstanding]: "Outstanding",
  };
  return get(mapping, value);
};

export const paymasterHistoricalPaymentOptions = sortBy(
  Object.values(PaymasterHistoricalPayment).map((value) => {
    return {
      value,
      label: toHumanReadablePaymasterHistoricalPayment(
        value as PaymasterHistoricalPayment
      ),
    };
  }),
  "label"
);

export const paymasterCreditControlOptions = sortBy(
  Object.values(PaymasterHistoricalCreditControl).map((value) => {
    return { value, label: toHumanReadable(value) };
  }),
  "label"
);

export const paymasterFinalScoreOptions = sortBy(
  Object.values(PaymasterFinalScore).map((value) => {
    return { value, label: toHumanReadable(value) };
  }),
  "label"
);
