import { Badge, Flex, Switch, Text } from "@mantine/core";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import ActionIcon from "../../../../components/ActionIcon/ActionIcon.tsx";
import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import Edit from "../../../../components/Icons/Edit.tsx";
import {
  PengurusStatus,
  RolePermission,
} from "../../../../graphql/generated.ts";
import { useCurrentUserContext } from "../../../../providers/CurrentUserProvider.tsx";
import { RawPengurus } from "../../../../types/limitRequest/pengurus.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { hasPermission } from "../../../../utils/user.ts";

interface Props {
  penguruses: RawPengurus[] | null | undefined;
  onEdit?: (value: RawPengurus, index: number) => void;
  onStatusChange?: (id: number, value: PengurusStatus) => void;
}

export const usePengurusesCardInfo = ({
  penguruses,
  onStatusChange,
  onEdit,
}: Props) => {
  const { user: currentUser } = useCurrentUserContext();

  const hasEditAccess = hasPermission(
    currentUser,
    RolePermission.CanEditBuyerInfoPengurusInfo
  );

  const handleStatusChange = useCallback(
    (item: RawPengurus) => {
      if (!item?.id || !onStatusChange) {
        return;
      }
      const newStatus =
        item.status === PengurusStatus.Active
          ? PengurusStatus.Inactive
          : PengurusStatus.Active;
      onStatusChange(item.id, newStatus);
    },
    [onStatusChange]
  );

  return useMemo(() => {
    return penguruses?.map((item, index) => ({
      cardControl: (
        <Flex gap={12}>
          <Badge
            size="s"
            variant={
              item.status === PengurusStatus.Active ? "active" : "inactive"
            }
          >
            {toHumanReadable(item.status)}
          </Badge>
          {hasEditAccess && onEdit && (
            <ActionIcon
              disabled={item.status === PengurusStatus.Inactive}
              spaces={false}
              onClick={() => onEdit(item, index)}
            >
              <Edit />
            </ActionIcon>
          )}
        </Flex>
      ),
      customVariant: (
        <Flex gap={12}>
          {hasEditAccess && onStatusChange && (
            <Switch
              variant="yellow"
              checked={item.status === PengurusStatus.Active}
              radius={2}
              onClick={() => handleStatusChange(item)}
            />
          )}
          <Text>{item.name}</Text>
        </Flex>
      ),
      items: [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "name",
              }}
              position="right"
            >
              Pengurus Name
            </DatabaseMetadataPopover>
          ),
          content: item.name,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "ktpPengurus",
              }}
              position="right"
            >
              KTP Pengurus
            </DatabaseMetadataPopover>
          ),
          content: (
            <Link
              to={item.ktpPengurus.url}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </Link>
          ),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "gender",
              }}
              position="right"
            >
              Gender
            </DatabaseMetadataPopover>
          ),
          content: toHumanReadable(item.gender),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "birthDate",
              }}
              position="right"
            >
              Date of Birth
            </DatabaseMetadataPopover>
          ),
          content: toLocalDate(item.birthDate),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "ktpNumber",
              }}
              position="right"
            >
              KTP Number
            </DatabaseMetadataPopover>
          ),
          content: item.ktpNumber,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "phone",
              }}
              position="right"
            >
              Phone
            </DatabaseMetadataPopover>
          ),
          content: item.phone,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "ktpAddress",
              }}
              position="right"
            >
              KTP Address
            </DatabaseMetadataPopover>
          ),
          content: item.ktpAddress,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "subDistrict",
              }}
              position="right"
            >
              Sub-district
            </DatabaseMetadataPopover>
          ),
          content: item.subDistrict,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "district",
              }}
              position="right"
            >
              District
            </DatabaseMetadataPopover>
          ),
          content: item.district,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "city",
              }}
              position="right"
            >
              City
            </DatabaseMetadataPopover>
          ),
          content: item.city.name,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "postalCode",
              }}
              position="right"
            >
              Postal Code
            </DatabaseMetadataPopover>
          ),
          content: item.postalCode,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "Pengurus",
                fieldName: "country",
              }}
              position="right"
            >
              Country
            </DatabaseMetadataPopover>
          ),
          content: item.country.name,
        },
      ],
    }));
  }, [penguruses, hasEditAccess, onEdit, onStatusChange, handleStatusChange]);
};
