import { Button, Flex, MediaQuery } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";

import LimitRequestInfoForm from "../../../../components/LimitRequestInfoForm/LimitRequestInfoForm.tsx";
import { ClikCity, ClikCountry } from "../../../../graphql/generated.ts";
import { ClikDataLimitRequest } from "../../../../types/clikData.ts";
import { CustomerOption } from "../../../../types/customer.ts";
import { RawLimitRequest } from "../../../../types/limitRequest/limitRequest.ts";
import { useStyles } from "../CompanyInfo.styles.ts";

interface Props {
  limitRequest: ClikDataLimitRequest | null | undefined;
  cities: ClikCity[] | null | undefined;
  countries: ClikCountry[] | null | undefined;
  companies: CustomerOption[] | null | undefined;
  onCancel: () => void;
  onSave: () => void;
  onChange: (value: RawLimitRequest) => void;
}

export const useEditLimitRequestCompanyInfo = ({
  companies,
  cities,
  countries,
  limitRequest,
  onCancel,
  onSave,
  onChange,
}: Props) => {
  const { classes } = useStyles();

  const [valid, setValid] = useState(true);

  const handleValueChange = useCallback(
    (value: RawLimitRequest, isValid: boolean) => {
      setValid(isValid);
      onChange(value);
    },
    [onChange, setValid]
  );

  return useMemo(() => {
    if (!limitRequest) {
      return null;
    }
    return (
      <>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <Flex gap={20}>
            <Button
              variant="outlineGolden"
              type="reset"
              uppercase
              onClick={onCancel}
            >
              cancel
            </Button>
            <Button
              variant="filledGolden"
              size="m"
              type="submit"
              uppercase
              disabled={!valid}
              onClick={onSave}
            >
              save
            </Button>
          </Flex>
        </MediaQuery>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Flex gap={20} className={classes.buttonsWrapper}>
            <Button
              variant="outlineGolden"
              type="reset"
              uppercase
              w="100%"
              onClick={onCancel}
            >
              cancel
            </Button>
            <Button
              variant="filledGolden"
              size="m"
              type="submit"
              uppercase
              w="100%"
              disabled={!valid}
              onClick={onSave}
            >
              save
            </Button>
          </Flex>
        </MediaQuery>
        <LimitRequestInfoForm
          showCategorySection={false}
          value={limitRequest}
          cities={cities}
          countries={countries}
          companies={companies}
          disabledFields={["company"]}
          onValueChange={handleValueChange}
        />
      </>
    );
  }, [
    classes,
    limitRequest,
    cities,
    countries,
    companies,
    valid,
    onSave,
    onCancel,
    handleValueChange,
  ]);
};
