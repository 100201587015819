import { NavLink } from "@mantine/core";
import get from "lodash/get";
import { useCallback } from "react";
import { NavLink as Link, useLocation } from "react-router-dom";

import { NavigationLink, NavigationNestedLink } from "../../types.ts";
import { useStyles } from "./SidebarItem.styles.ts";

interface SidebarItemProps {
  item: NavigationLink;
  isOpen?: boolean;
  onClick?: () => void;
}

const SidebarItem = ({ item, isOpen, onClick }: SidebarItemProps) => {
  const location = useLocation();

  const { classes } = useStyles();

  const isLinkActive = useCallback(
    (link: NavigationLink | NavigationNestedLink) =>
      link.url ? location.pathname.includes(link.url) : false,
    [location.pathname]
  );

  const hasActiveSubLink = useCallback(
    (link: NavigationLink | NavigationNestedLink): boolean =>
      !!link.nestedLinks?.some((nestedLink) =>
        nestedLink.nestedLinks
          ? hasActiveSubLink(nestedLink)
          : isLinkActive(nestedLink)
      ),
    [isLinkActive]
  );

  const renderLink = useCallback(
    (link: NavigationLink | NavigationNestedLink) => {
      return link.url ? (
        <Link key={link.label} to={link.url} className={classes.wrapper}>
          <NavLink
            key={link.label}
            icon={get(link, "icon")}
            active={isLinkActive(link)}
            label={link.label}
            onClick={onClick}
          />
        </Link>
      ) : (
        <NavLink
          key={link.label}
          label={link.label}
          icon={get(link, "icon")}
          defaultOpened={hasActiveSubLink(link)}
        >
          {link.nestedLinks?.map(
            (nestedLink) => nestedLink.show !== false && renderLink(nestedLink)
          )}
        </NavLink>
      );
    },
    [classes.wrapper, hasActiveSubLink, isLinkActive, onClick]
  );

  return (
    <>
      {isOpen ? (
        renderLink(item)
      ) : (
        <NavLink
          icon={item.icon}
          active={isLinkActive(item) || hasActiveSubLink(item)}
        />
      )}
    </>
  );
};

export default SidebarItem;
