import { useCallback } from "react";

import { AppRoute } from "../../../constants.ts";
import { RolePermission } from "../../../graphql/generated.ts";
import { useCurrentUserContext } from "../../../providers/CurrentUserProvider.tsx";
import { canViewCreditLimitMenu } from "../../../utils/permissions.ts";
import { hasPermission } from "../../../utils/user.ts";
import SvgAIIcon from "../../Icons/AIIcon.tsx";
import SvgCreditCard from "../../Icons/CreditCard.tsx";
import SvgFile from "../../Icons/File.tsx";
import SvgFolder from "../../Icons/Folder.tsx";
import SvgSettings from "../../Icons/Settings.tsx";
import SvgUser from "../../Icons/User.tsx";
import SvgUserScan from "../../Icons/UserScan.tsx";
import SidebarItem from "../Sidebar/SidebarItem/SidebarItem.tsx";
import { NavigationLink } from "../types.ts";

interface Props {
  isOpen?: boolean;
  onClick?: () => void;
}

export function useNavigationLinks({ isOpen = true, onClick }: Props) {
  const { user } = useCurrentUserContext();

  const links: NavigationLink[] = [
    {
      label: "Setting",
      icon: <SvgSettings />,
      nestedLinks: [
        {
          label: "User Access",
          url: AppRoute.UserAccessSetting,
          show: hasPermission(user, RolePermission.CanViewUserAccessSetting),
        },
        {
          label: "Prompt Editor",
          url: AppRoute.PropmtEditor,
          show: hasPermission(user, RolePermission.CanViewPrompt),
        },
      ],
      show:
        hasPermission(user, RolePermission.CanViewUserAccessSetting) ||
        hasPermission(user, RolePermission.CanViewPrompt),
    },
    {
      label: "Customer Database",
      icon: <SvgUser />,
      url: AppRoute.Customers,
      show: hasPermission(user, RolePermission.CanViewCustomerDatabase),
    },
    {
      label: "Credit Risk",
      icon: <SvgCreditCard />,
      show: canViewCreditLimitMenu(user),
      nestedLinks: [
        {
          label: "CLIK Data",
          url: AppRoute.ClikData,
          show: hasPermission(user, RolePermission.CanViewClikData),
        },
        {
          label: "Risk Funnel Dashboard",
          url: AppRoute.RiskFunnelDashboard,
          show: hasPermission(user, RolePermission.CanViewRiskFunnelDashboard),
        },
        {
          label: "Ongoing Process",
          show:
            hasPermission(user, RolePermission.CanViewCreditUnderwriting) ||
            hasPermission(user, RolePermission.CanViewRiskReview) ||
            hasPermission(user, RolePermission.CanViewRiskMonitoring),
          nestedLinks: [
            {
              label: "Credit Underwriting",
              url: AppRoute.CreditUnderwriting,
              show: hasPermission(
                user,
                RolePermission.CanViewCreditUnderwriting
              ),
            },
            {
              label: "Risk Review",
              url: AppRoute.RiskReview,
              show: hasPermission(user, RolePermission.CanViewRiskReview),
            },
            {
              label: "Risk Monitoring",
              url: AppRoute.RiskMonitoring,
              show: hasPermission(user, RolePermission.CanViewRiskMonitoring),
            },
          ],
        },
      ],
    },
    {
      label: "AI Automation",
      icon: <SvgAIIcon />,
      show: hasPermission(user, RolePermission.CanViewAiAutomation),
      nestedLinks: [
        {
          label: "Run Automation",
          url: AppRoute.RunAIAutomation,
        },
        {
          label: "Result Database",
          url: AppRoute.AIResult,
        },
      ],
    },
    {
      label: "Collection",
      icon: <SvgFolder />,
      show:
        hasPermission(user, RolePermission.CanViewCollection) ||
        hasPermission(user, RolePermission.CanViewActivityManagement),
      nestedLinks: [
        {
          label: "Collection Dashboard",
          url: AppRoute.CollectionDashboard,
          show: hasPermission(user, RolePermission.CanViewCollection),
        },
        {
          label: "Activity Management",
          url: AppRoute.ActivityManagement,
          show: hasPermission(user, RolePermission.CanViewActivityManagement),
        },
        {
          label: "Review & Approval",
          url: AppRoute.ReviewAndApproval,
          show: hasPermission(user, RolePermission.CanViewReviewApproval),
        },
        {
          label: "Late Fee Calculator",
          url: AppRoute.LateFeeCalculator,
          show: hasPermission(user, RolePermission.CanViewActivityManagement),
        },
      ],
    },
    {
      label: "Paymaster Analysis",
      icon: <SvgFile />,
      url: AppRoute.PaymasterAnalysis,
      show: hasPermission(user, RolePermission.CanViewPaymaster),
    },
    {
      label: "Employee Candidate CLIK",
      icon: <SvgUserScan />,
      url: AppRoute.EmployeeCandidate,
      show: hasPermission(user, RolePermission.CanViewEmployeeCandidateClik),
    },
  ];

  const getRenderedLink = useCallback(
    (link: NavigationLink, index: number) => {
      if (link.show === false) {
        return null;
      }

      return (
        <SidebarItem
          key={index}
          item={link}
          isOpen={isOpen}
          onClick={onClick}
        />
      );
    },
    [isOpen, onClick]
  );

  return links.map(getRenderedLink);
}
