import { Button, Flex } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useCallback } from "react";

import SvgPlus from "../../components/Icons/Plus.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import Title from "../../components/Title/Title.tsx";
import {
  AiPromptStatusFilterOptions,
  CreateAiPromptInput,
  OrderBy,
  RolePermission,
} from "../../graphql/generated.ts";
import { useAIPrompts } from "../../hooks/api/ai/prompt/useAIPrompts.ts";
import { useCreateAIPrompt } from "../../hooks/api/ai/prompt/useCreateAIPrompt.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../providers/CurrentUserProvider.tsx";
import { AIPrompt } from "../../types/ai/prompt.ts";
import { hasPermission } from "../../utils/user.ts";
import { PromptEditorItem } from "./PromptEditorItem.tsx";
import { PromptModal } from "./PromptModal.tsx";

const PromptEditorPage = () => {
  const { user } = useCurrentUserContext();

  const [{ data: promptsResponse, fetching: fetchingPrompts }, refreshPrompts] =
    useAIPrompts({
      filter: { status: { equals: AiPromptStatusFilterOptions.Active } },
      orderBy: {
        createdAt: OrderBy.Desc,
      },
    });
  const prompts = promptsResponse?.prompts || [];

  const [, createPrompt] = useMutationNotificationWrapper(useCreateAIPrompt(), {
    success: {
      message: "Prompt has been successfully created",
    },
  });

  const [
    createModalOpened,
    { open: createModalOpen, close: createModalClose },
  ] = useDisclosure(false);

  const canEdit = hasPermission(user, RolePermission.CanEditPrompt);

  const handleCreatePrompt = useCallback(
    (input: CreateAiPromptInput) => {
      createPrompt({ input }).then((data) => {
        if (!data.error) {
          refreshPrompts();
        }
      });
    },
    [createPrompt, refreshPrompts]
  );

  return (
    <>
      <Layout>
        <Preloader loading={fetchingPrompts} />
        <Flex direction="column" gap={{ base: 16, sm: 24 }}>
          <Flex direction="column" gap={16}>
            <Title size="h1">Prompt Editor</Title>
          </Flex>
          {canEdit && (
            <Button
              uppercase
              variant="filledGolden"
              leftIcon={<SvgPlus />}
              onClick={createModalOpen}
            >
              Add
            </Button>
          )}
          {prompts.map((prompt: AIPrompt) => (
            <PromptEditorItem
              key={prompt.id}
              prompt={prompt}
              refresh={refreshPrompts}
            />
          ))}
        </Flex>
        {createModalOpened && (
          <PromptModal
            opened={true}
            onClose={createModalClose}
            onSubmit={handleCreatePrompt}
          />
        )}
      </Layout>
    </>
  );
};

export default PromptEditorPage;
