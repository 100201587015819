import { useDisclosure } from "@mantine/hooks";
import { useCallback, useMemo } from "react";

import MultiFieldCard from "../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal.tsx";
import {
  LimitRequestStatus,
  RolePermission,
  UpdateCreditApprovalInput,
} from "../../../graphql/generated.ts";
import { useCreateCreditApproval } from "../../../hooks/api/creditApproval/useCreateCreditApproval.ts";
import { useCreditApproval } from "../../../hooks/api/creditApproval/useCreditApproval.ts";
import { useUpdateCreditApproval } from "../../../hooks/api/creditApproval/useUpdateCreditApproval.ts";
import { useHandoverLimitRequestToOL } from "../../../hooks/api/limitRequest/useHandoverLimitRequestToOL.ts";
import { useRejectLimitRequestByCC } from "../../../hooks/api/limitRequest/useRejectLimitRequestByCC.ts";
import { useRejectLimitRequestByRR } from "../../../hooks/api/limitRequest/useRejectLimitRequestByRR.ts";
import { useGetLimitRequestConditionPrecedents } from "../../../hooks/api/limitRequestConditionPrecedent/useGetLimitRequestConditionPrecedents.ts";
import { useGetLimitRequestExceptions } from "../../../hooks/api/limitRequestException/useGetLimitRequestExceptions.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../../providers/CurrentUserProvider.tsx";
import { LimitRequestDetail } from "../../../types/limitRequest/limitRequest.ts";
import { hasPermission } from "../../../utils/user.ts";
import ConditionPrecedentsModal from "../../ClikDataDetails/TransactionHistory/ConditionPrecedentsModal/ConditionPrecedentsModal.tsx";
import ExceptionModal from "../../ClikDataDetails/TransactionHistory/ExceptionModal/ExceptionModal.tsx";
import LimitRequestDetailCardControl from "../LimitRequestDetailCardControl/LimitRequestDetailCardControl.tsx";
import CreditCommitteeModal from "./CreditCommitteeModal.tsx";
import { useCreditCommitteeCardData } from "./hooks/useCreditCommitteeCardData.tsx";
import { useCreditCommitteeCardFooter } from "./hooks/useCreditCommitteeCardFooter.tsx";

interface Props {
  limitRequest: LimitRequestDetail | null | undefined;
  onRefreshLimitRequest: () => void;
}

const CreditCommitteeCard = ({
  limitRequest,
  onRefreshLimitRequest,
}: Props) => {
  const [
    creditCommitteeModalOpened,
    { open: creditCommitteeModalOpen, close: creditCommitteeModalClose },
  ] = useDisclosure(false);
  const [
    conditionPrecedentEditModalOpened,
    {
      open: conditionPrecedentEditModalOpen,
      close: conditionPrecedentEditModalClose,
    },
  ] = useDisclosure(false);

  const [
    exceptionEditModalOpened,
    { open: exceptionEditModalOpen, close: exceptionEditModalClose },
  ] = useDisclosure(false);

  const [
    { data: creditCommitteeResponse, fetching: fetchingCreditCommittee },
    refreshCreditCommittee,
  ] = useCreditApproval({
    id: limitRequest?.creditApproval?.id,
  });
  const creditCommittee = creditCommitteeResponse?.creditApproval;

  const { user } = useCurrentUserContext();

  const [{ data: conditionPrecedentsResponse }, refreshConditionPrecedence] =
    useGetLimitRequestConditionPrecedents({
      limitRequestId: limitRequest?.id,
    });
  const conditionPrecedents =
    conditionPrecedentsResponse?.limitRequestConditionPrecedents?.filter(
      (item) => item.active
    );

  const [{ data: exceptionsResponse }, refreshExceptionsList] =
    useGetLimitRequestExceptions({
      limitRequestId: limitRequest?.id,
    });
  const exceptions = exceptionsResponse?.limitRequestExceptions?.filter(
    (item) => item.active
  );

  const showConditionPrecedentEditModal =
    conditionPrecedentEditModalOpened && limitRequest && conditionPrecedents;
  const showExceptionEditModal =
    exceptionEditModalOpened && limitRequest && exceptions;

  const [
    showRejectByCCConfirmationModal,
    {
      open: openRejectByCCConfirmationModal,
      close: closeRejectByCCConfirmationModal,
    },
  ] = useDisclosure(false);
  const [
    showRejectByRRConfirmationModal,
    {
      open: openRejectByRRConfirmationModal,
      close: closeRejectByRRConfirmationModal,
    },
  ] = useDisclosure(false);

  const [, updateCreditCommittee] = useMutationNotificationWrapper(
    useUpdateCreditApproval(),
    {
      success: { message: "Credit Committee data was successfully updated." },
    }
  );

  const [, createCreditCommittee] = useMutationNotificationWrapper(
    useCreateCreditApproval(),
    {
      success: { message: "Credit Committee data was successfully created." },
    }
  );

  const [, handoverLimitRequestToOL] = useMutationNotificationWrapper(
    useHandoverLimitRequestToOL(),
    {
      success: {
        message:
          "Limit Request Status was successfully handovered to Offering Letter.",
      },
    }
  );

  const [, rejectLimitRequestByCC] = useMutationNotificationWrapper(
    useRejectLimitRequestByCC(),
    {
      success: {
        message: "Limit Request was successfully rejected.",
      },
    }
  );

  const [, rejectLimitRequestByRR] = useMutationNotificationWrapper(
    useRejectLimitRequestByRR(),
    {
      success: {
        message: "Limit Request was successfully rejected.",
      },
    }
  );

  const hasEditAccess = hasPermission(
    user,
    RolePermission.CanEditBuyerInfoLimitInfoCreditCommittee
  );

  const handleHandoverOL = useCallback(() => {
    if (!limitRequest?.id) {
      return;
    }
    handoverLimitRequestToOL({ id: limitRequest.id }).then(
      onRefreshLimitRequest
    );
  }, [limitRequest, handoverLimitRequestToOL, onRefreshLimitRequest]);

  const handleRejectByCC = useCallback(() => {
    if (!limitRequest?.id) {
      return;
    }
    rejectLimitRequestByCC({ id: limitRequest.id })
      .then(onRefreshLimitRequest)
      .finally(closeRejectByCCConfirmationModal);
  }, [
    limitRequest,
    rejectLimitRequestByCC,
    onRefreshLimitRequest,
    closeRejectByCCConfirmationModal,
  ]);

  const handleRejectByRR = useCallback(() => {
    if (!limitRequest?.id) {
      return;
    }
    rejectLimitRequestByRR({ id: limitRequest.id })
      .then(onRefreshLimitRequest)
      .finally(closeRejectByRRConfirmationModal);
  }, [
    limitRequest,
    rejectLimitRequestByRR,
    closeRejectByRRConfirmationModal,
    onRefreshLimitRequest,
  ]);

  const handleUpdateCreditCommittee = useCallback(
    (input: UpdateCreditApprovalInput) => {
      if (!limitRequest?.id) {
        return;
      }
      if (!creditCommittee) {
        createCreditCommittee({
          input: { ...input, limitRequestId: limitRequest.id },
        })
          .then(() => onRefreshLimitRequest())
          .then(() => refreshCreditCommittee());
      }
      if (!creditCommittee?.id) {
        return;
      }
      updateCreditCommittee({ id: Number(creditCommittee.id), input })
        .then(() => onRefreshLimitRequest())
        .then(() => refreshCreditCommittee());
    },
    [
      refreshCreditCommittee,
      onRefreshLimitRequest,
      creditCommittee,
      limitRequest,
      createCreditCommittee,
      updateCreditCommittee,
    ]
  );

  const creditCommitteeInfo = useCreditCommitteeCardData({
    limitRequest,
    creditCommittee,
    exceptions,
    conditionPrecedents,
    hasEditAccess,
    onEditConditionPrecedents: conditionPrecedentEditModalOpen,
    onEditExceptions: exceptionEditModalOpen,
  });

  const cardControl = useMemo(() => {
    if (!creditCommittee) {
      return null;
    }
    return (
      <LimitRequestDetailCardControl
        updatedBy={creditCommittee?.updatedBy}
        edit={
          hasEditAccess &&
          creditCommittee &&
          limitRequest?.requestStatus === LimitRequestStatus.OngoingCc
        }
        onEdit={creditCommitteeModalOpen}
      />
    );
  }, [creditCommittee, hasEditAccess, limitRequest, creditCommitteeModalOpen]);

  const cardFooterContent = useCreditCommitteeCardFooter({
    limitRequest,
    creditCommittee,
    canEdit: hasEditAccess,
    onAdd: creditCommitteeModalOpen,
    onHandoverToOL: handleHandoverOL,
    onRejectByCC: openRejectByCCConfirmationModal,
    onRejectByRR: openRejectByRRConfirmationModal,
  });

  return (
    <>
      <MultiFieldCard
        label="Credit Committee"
        items={creditCommitteeInfo}
        cardControl={cardControl}
        loading={fetchingCreditCommittee}
        cardFooterContent={cardFooterContent}
        variant="blue"
        disabled={
          !creditCommittee &&
          limitRequest?.requestStatus !== LimitRequestStatus.OngoingCc
        }
      />
      {creditCommitteeModalOpened && (
        <CreditCommitteeModal
          value={creditCommittee}
          limitRequestId={limitRequest?.id}
          conditionPrecedents={conditionPrecedents}
          exceptions={exceptions}
          opened={true}
          onClose={creditCommitteeModalClose}
          onSubmit={handleUpdateCreditCommittee}
          onRefreshConditionPrecedence={refreshConditionPrecedence}
          onRefreshExceptionsList={refreshExceptionsList}
        />
      )}
      {showConditionPrecedentEditModal && (
        <ConditionPrecedentsModal
          limitRequestId={limitRequest.id}
          data={conditionPrecedents}
          opened={true}
          onClose={() => {
            conditionPrecedentEditModalClose();
            refreshConditionPrecedence();
          }}
        />
      )}
      {showExceptionEditModal && (
        <ExceptionModal
          limitRequestId={limitRequest.id}
          data={exceptions}
          opened={true}
          onClose={() => {
            exceptionEditModalClose();
            refreshExceptionsList();
          }}
        />
      )}
      {showRejectByCCConfirmationModal && (
        <ConfirmationModal
          opened={true}
          message={
            "Are you sure you want to Reject this limit? This process is Irreversible."
          }
          onConfirm={handleRejectByCC}
          onClose={closeRejectByCCConfirmationModal}
        />
      )}
      {showRejectByRRConfirmationModal && (
        <ConfirmationModal
          opened={true}
          message={
            "Are you sure you want to Reject this limit? This process is Irreversible."
          }
          onConfirm={handleRejectByRR}
          onClose={closeRejectByRRConfirmationModal}
        />
      )}
    </>
  );
};

export default CreditCommitteeCard;
