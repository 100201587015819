import { Badge, Button, Flex } from "@mantine/core";
import { useMemo } from "react";

import SvgCheck from "../../../../components/Icons/Check.tsx";
import SvgClose from "../../../../components/Icons/Close.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import {
  CaResult,
  LimitRequestStatus,
  LimitStatus,
} from "../../../../graphql/generated.ts";
import { CreditApproval } from "../../../../types/creditApproval.ts";
import { LimitRequestDetail } from "../../../../types/limitRequest/limitRequest.ts";

interface Props {
  limitRequest: LimitRequestDetail | null | undefined;
  creditCommittee: Partial<CreditApproval> | null | undefined;
  canEdit: boolean;
  onAdd: () => void;
  onHandoverToOL: () => void;
  onRejectByCC: () => void;
  onRejectByRR: () => void;
}

export const useCreditCommitteeCardFooter = ({
  limitRequest,
  creditCommittee,
  canEdit,
  onAdd,
  onHandoverToOL,
  onRejectByCC,
  onRejectByRR,
}: Props) => {
  const renderedContent = useMemo(() => {
    if (!creditCommittee) {
      return (
        <Button
          leftIcon={<SvgPlus />}
          variant="filledGolden"
          w="100%"
          uppercase
          disabled={
            limitRequest?.requestStatus !== LimitRequestStatus.OngoingCc ||
            !canEdit
          }
          onClick={onAdd}
        >
          Add data
        </Button>
      );
    }
    if (
      limitRequest?.requestStatus === LimitRequestStatus.Rejected &&
      limitRequest.limitDetail?.status === LimitStatus.RejectedByRr
    ) {
      return (
        <Badge size="m" variant="updated" w="100%">
          <Flex>
            <SvgClose />
            Rejected by RR
          </Flex>
        </Badge>
      );
    }
    if (
      limitRequest?.requestStatus === LimitRequestStatus.Rejected &&
      limitRequest.limitDetail?.status === LimitStatus.RejectedByCc
    ) {
      return (
        <Badge size="m" variant="updated" w="100%">
          <Flex>
            <SvgClose />
            Rejected by CC
          </Flex>
        </Badge>
      );
    }
    // If the limit approved is above than IDR 500.000.000 (500 Million), then Handover to OL button will
    // be changed into Handover to CC (In Badge form)
    if (
      creditCommittee.result === CaResult.ApprovedByRr &&
      creditCommittee.limitRecommendation > 500000000
    ) {
      return (
        <Badge variant="filled" color="golden" w="100%" size="m">
          <Flex>Handover to CC</Flex>
        </Badge>
      );
    }
    if (creditCommittee.result === CaResult.RejectedByCc) {
      return (
        <Button
          variant="filledGolden"
          leftIcon={<SvgClose />}
          uppercase
          w="100%"
          disabled={!canEdit}
          onClick={onRejectByCC}
        >
          Reject by CC
        </Button>
      );
    }
    if (creditCommittee.result === CaResult.RejectedByRr) {
      return (
        <Button
          variant="filledGolden"
          leftIcon={<SvgClose />}
          uppercase
          w="100%"
          disabled={!canEdit}
          onClick={onRejectByRR}
        >
          Reject by RR
        </Button>
      );
    }
    if (limitRequest?.requestStatus === LimitRequestStatus.OngoingCc) {
      return (
        <Button
          variant="filledGolden"
          leftIcon={<SvgCheck />}
          uppercase
          w="100%"
          disabled={!canEdit}
          onClick={onHandoverToOL}
        >
          Handover to OL
        </Button>
      );
    }
    return (
      <Badge variant="active" w="100%" size="m">
        <Flex>
          <SvgCheck />
          Handovered to OL
        </Flex>
      </Badge>
    );
  }, [
    creditCommittee,
    limitRequest,
    canEdit,
    onAdd,
    onRejectByCC,
    onRejectByRR,
    onHandoverToOL,
  ]);

  return useMemo(() => {
    return (
      <Flex w="100%" h="100%" align="end">
        {renderedContent}
      </Flex>
    );
  }, [renderedContent]);
};
