import { RolePermission } from "../graphql/generated.ts";
import { User } from "../types/user.ts";
import { hasPermission } from "./user.ts";

export const canViewCreditLimitMenu = (user: User | undefined | null) =>
  hasPermission(user, RolePermission.CanViewClikData) ||
  hasPermission(user, RolePermission.CanViewRiskFunnelDashboard) ||
  hasPermission(user, RolePermission.CanViewCreditUnderwriting) ||
  hasPermission(user, RolePermission.CanViewRiskReview) ||
  hasPermission(user, RolePermission.CanViewRiskMonitoring);
