import {
  CloseButton,
  Flex,
  Select,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import SvgWarning from "../../../components/Icons/Warning.tsx";
import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table.tsx";
import { SortDirection, TableRow } from "../../../components/Table/types.ts";
import { AppRoute } from "../../../constants.ts";
import {
  LimitRequestFilter,
  LimitRequestOrderBy,
  LimitRequestStatusFilterOptions,
  OrderBy,
  StringFilterMode,
} from "../../../graphql/generated.ts";
import { useLimitRequests } from "../../../hooks/api/limitRequest/useLimitRequests.ts";
import { CustomerOption } from "../../../types/customer.ts";
import { limitTypeOptions } from "../../../types/limit.ts";
import { LimitRequest } from "../../../types/limitRequest/limitRequest.ts";
import { UserOption } from "../../../types/user.ts";
import { getCompanyOptions } from "../../../utils/company.ts";
import { getLimitRequestApiFilter } from "../../../utils/filter.ts";
import { getPagesCount } from "../../../utils/pagination.ts";
import { getUserOptions } from "../../../utils/user.ts";
import { CUStatusOptions } from "../constants.ts";
import { getLimitRequestApiSort } from "../utils.ts";
import { useOngoingCUTableData } from "./hooks/useOngoingCUTableData.tsx";

interface Props {
  companies: CustomerOption[] | undefined;
  assignees: UserOption[] | undefined;
}

const OngoingCUTable = ({ companies, assignees }: Props) => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<LimitRequestFilter>({});
  const [search, setSearch] = useState<string | undefined>();
  const [debouncedSearch] = useDebouncedValue(search, 300);
  const [orderBy, setOrderBy] = useState<LimitRequestOrderBy>({
    createdAt: OrderBy.Desc,
  });
  const [page, setPage] = useState<number>(1);

  const [{ data, fetching, error }] = useLimitRequests({
    filter: {
      ...filter,
      requestStatus: filter?.requestStatus
        ? filter.requestStatus
        : {
            in: [
              LimitRequestStatusFilterOptions.OngoingCu,
              LimitRequestStatusFilterOptions.OngoingCuManagerReview,
            ],
          },
      limitName: debouncedSearch
        ? {
            contains: debouncedSearch,
            mode: StringFilterMode.Insensitive,
          }
        : undefined,
    },
    orderBy,
    page,
  });

  const ongoingCUData: LimitRequest[] | null | undefined =
    data?.limitRequests?.data;

  const pageCount = getPagesCount(data?.limitRequests?.total);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getLimitRequestApiSort(sort));
    },
    [setOrderBy]
  );

  const handleFilterChange = useCallback((key: string, value: unknown) => {
    setFilter(getLimitRequestApiFilter({ [key]: value }));
  }, []);

  const handleRowClik = useCallback(
    (record: TableRow) => {
      navigate(`${AppRoute.LimitRequest}/${record.id}`);
    },
    [navigate]
  );

  const [columns, rows] = useOngoingCUTableData({
    data: ongoingCUData,
  });

  if (error) {
    notifications.clean();
    notifications.show({
      message:
        "Something went wrong while trying to fetch Limit Requests data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <Flex direction="column" gap={20}>
      <SectionTitle>Ongoing CU</SectionTitle>
      <SimpleGrid
        cols={3}
        breakpoints={[{ maxWidth: "sm", cols: 2 }]}
        spacing={20}
        verticalSpacing={12}
        pb={8}
      >
        <Select
          size="m"
          clearable
          searchable
          label="Company name"
          placeholder="Select Company name"
          data={getCompanyOptions(companies)}
          onChange={(value) => handleFilterChange("companyName", value)}
        />
        <Select
          size="m"
          clearable
          searchable
          label="CU Assigned"
          placeholder="Select CU Assigned"
          data={getUserOptions(assignees)}
          onChange={(value) =>
            handleFilterChange("cuAssignedId", Number(value))
          }
        />
        <Select
          size="m"
          clearable
          searchable
          label="CU Status"
          placeholder="Select CU Status"
          data={CUStatusOptions}
          onChange={(value) => handleFilterChange("requestStatus", value)}
        />
        <Select
          size="m"
          clearable
          label="Limit Type"
          placeholder="Select Limit Type"
          data={limitTypeOptions}
          onChange={(value) => handleFilterChange("limitType", value)}
        />
        <TextInput
          size="m"
          label="Limit Name"
          placeholder="Enter Limit Name"
          rightSection={
            <CloseButton
              aria-label="Clear input"
              variant="transparent"
              size="md"
              mr="5px"
              style={{
                display: search ? undefined : "none",
              }}
              onClick={() => setSearch("")}
            />
          }
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
        />
      </SimpleGrid>
      <Table
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "createdAt",
          direction: SortDirection.asc,
        }}
        loading={fetching}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onRowClick={handleRowClik}
        onSortChange={handleSortChange}
      />
    </Flex>
  );
};

export default OngoingCUTable;
