import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(
  (theme, { maxWidth }: { maxWidth: number | string }) => ({
    wrapper: {
      width: "100%",
      maxWidth: rem(maxWidth),
      borderRadius: rem(4),
      border: `1px dashed ${theme.colors.grayIce[0]}`,
      color: theme.colors.grayIce[0],

      "&:hover": {
        border: `1px dashed ${theme.colors.dusk[0]}`,
        color: theme.colors.dusk[0],
        background: theme.colors.grayIce[6],
      },
    },
    uploadedFiles: {
      padding: rem(12),
    },
    uploadWrapper: {
      display: "flex",
      alignItems: "center",
      minHeight: rem(72),
      padding: rem(12),
    },
    label: {
      fontSize: rem(16),
      lineHeight: 1.5,
      fontWeight: 500,
    },
    subLabel: {
      fontSize: rem(14),
      lineHeight: 1.5,
    },
    error: {
      color: theme.colors.error[0],
      borderColor: theme.colors.error[0],
    },

    disabled: {
      background: theme.colors.grayIce[3],
      cursorPointer: "none",
      border: `1px dashed ${theme.colors.grayIce[0]}`,

      "&:hover": {
        border: `1px dashed ${theme.colors.grayIce[0]}`,
        color: theme.colors.grayIce[0],
        background: theme.colors.grayIce[3],
      },
    },
  })
);
