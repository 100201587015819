import {
  GetLimitRequestQuery,
  GetLimitRequestsQuery,
  LimitRequest as GqlLimitRequest,
  LimitRequestCategory,
  LimitRequestType,
  LimitTopProductCategory,
} from "../../graphql/generated.ts";
import { toHumanReadable } from "../../utils/string.ts";
import { Customer } from "../customer.ts";
import { UserOption } from "../user.ts";
import { RawGroup } from "./group.ts";
import { RawPengurus } from "./pengurus.ts";

export type LimitRequestDetail = GetLimitRequestQuery["limitRequest"];

export type LimitRequest = GetLimitRequestsQuery["limitRequests"]["data"][0];
export type RawLimitRequest = Omit<
  GqlLimitRequest,
  | "id"
  | "createdAt"
  | "cuAssigned"
  | "limitRemaining"
  | "limitUtilized"
  | "penguruses"
  | "groups"
  | "company"
  | "conditionPrecedents"
  | "exceptions"
> & {
  id?: number;
  penguruses?: RawPengurus[];
  groups?: RawGroup[];
  cuAssigned?: UserOption;
  company: Pick<
    Customer,
    "id" | "name" | "salesName" | "salesTeamName" | "hasGeneralLimit"
  >;
};

export const limitRequestCategoryOptions = Object.values(
  LimitRequestCategory
).map((value) => {
  return { value, label: toHumanReadable(value) };
});

export const topProductCategoryOptions = Object.values([
  LimitTopProductCategory.TopBronze,
  LimitTopProductCategory.TopGold,
  LimitTopProductCategory.TopPlatinum,
  LimitTopProductCategory.TopSilver,
]).map((value) => {
  return { value, label: toHumanReadable(value) };
});

export const limitRequestTypeOptions = Object.values(LimitRequestType).map(
  (item) => ({
    value: item,
    label: toHumanReadable(item),
  })
);

export const generateLimitName = (
  limitRequestType: LimitRequestType | undefined,
  companyName: string | undefined
) => {
  if (!limitRequestType || !companyName) {
    return "";
  }
  if (limitRequestType === LimitRequestType.NewGeneralLimit) {
    return `${companyName} - General Limit`;
  }
  if (limitRequestType === LimitRequestType.TopUpLimit) {
    return `${companyName} - Top Up Limit`;
  }
  if (limitRequestType === LimitRequestType.NewProjectLimit) {
    return `${companyName} - Project Limit`;
  }
  return "";
};

export const tenorOptions = [30, 45, 60, 75, 90].map((t) => ({
  value: String(t),
  label: String(t),
}));

export const pricingApprovedMapping: Record<number, number> = {
  30: 4.0,
  45: 4.75,
  60: 5.5,
  75: 6.25,
  90: 7.0,
};
