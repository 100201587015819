import { Button, Flex, SimpleGrid } from "@mantine/core";
import { useCallback, useState } from "react";

import MultiFieldCard from "../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SvgEdit from "../../../components/Icons/Edit.tsx";
import {
  ClikCity,
  ClikCountry,
  RolePermission,
  UpdateCustomerDirectorInfoInput,
} from "../../../graphql/generated.ts";
import { useCreateCustomerDirectorInfo } from "../../../hooks/api/customer/useCreateCustomerDirectorInfo.ts";
import { useGetCustomerDirectorInfo } from "../../../hooks/api/customer/useGetCustomerDirectorInfo.ts";
import { useUpdateCustomerDirectorInfo } from "../../../hooks/api/customer/useUpdateCustomerDirectorInfo.ts";
import { useUpdateLimitRequest } from "../../../hooks/api/limitRequest/useUpdateLimitRequest.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../../providers/CurrentUserProvider.tsx";
import { ClikDataLimitRequest } from "../../../types/clikData.ts";
import { CustomerOption } from "../../../types/customer.ts";
import { RawLimitRequest } from "../../../types/limitRequest/limitRequest.ts";
import { toClikPhoneFormat } from "../../../utils/phone.ts";
import { hasPermission } from "../../../utils/user.ts";
import DirectorInfoForm from "./DirectorInfoForm.tsx";
import { useCustomerDirectorInfoCard } from "./hooks/useCustomerDirectorInfoCard.tsx";
import { useEditLimitRequestCompanyInfo } from "./hooks/useEditLimitRequestCompanyInfo.tsx";
import { useLimitRequestCompanyInfoCards } from "./hooks/useLimitRequestCompanyInfoCards.tsx";
import { EditMode } from "./types.ts";
import { formatLimitRequestToUpdateLimitRequestInput } from "./utils.ts";

interface CompanyInfoProps {
  isFetching: boolean;
  limitRequest: ClikDataLimitRequest | null | undefined;
  cities: ClikCity[] | null | undefined;
  countries: ClikCountry[] | null | undefined;
  companies: CustomerOption[] | null | undefined;
  onRefresh: () => void;
}

const CompanyInfo = ({
  isFetching,
  companies,
  cities,
  countries,
  limitRequest,
  onRefresh,
}: CompanyInfoProps) => {
  const [editMode, setEditMode] = useState<EditMode | null>(null);

  const [value, setValue] = useState<RawLimitRequest | null | undefined>(
    limitRequest
  );

  const [
    { data: customerDirectorInfo, fetching: fetchingCustomerDirectorInfo },
    refreshCustomerDirectorInfo,
  ] = useGetCustomerDirectorInfo({ customerId: limitRequest?.company?.id });

  const { user: currentUser } = useCurrentUserContext();

  const [companyInfo, companyAddress] = useLimitRequestCompanyInfoCards({
    limitRequest,
  });

  const customerDirectorInfoData = customerDirectorInfo?.customerDirectorInfo;

  const directorInfo = useCustomerDirectorInfoCard({
    data: customerDirectorInfoData,
  });

  const hasEditAccess = hasPermission(
    currentUser,
    RolePermission.CanEditBuyerInfoCompanyInfo
  );

  const [, updateLimitRequest] = useMutationNotificationWrapper(
    useUpdateLimitRequest(),
    {
      success: {
        message: "Limit Request has been updated successfully.",
      },
    }
  );

  const [, updateDirectorInfo] = useMutationNotificationWrapper(
    useUpdateCustomerDirectorInfo(),
    {
      success: {
        message: "Director Info has been updated successfully.",
      },
    }
  );

  const [, createDirectorInfo] = useMutationNotificationWrapper(
    useCreateCustomerDirectorInfo(),
    {
      success: {
        message: "Director Info has been updated successfully.",
      },
    }
  );

  const handleEdit = (type: EditMode) => setEditMode(type);

  const handleCancel = () => setEditMode(null);

  const handleValueChange = (value: RawLimitRequest) => {
    setValue(value);
  };

  const handleSave = () => {
    if (!value || !limitRequest) {
      return;
    }
    const input = formatLimitRequestToUpdateLimitRequestInput(value);
    if (!input) {
      return;
    }
    updateLimitRequest({
      id: Number(limitRequest.id),
      input: {
        ...input,
        phone: toClikPhoneFormat(input.phone),
      },
    }).then(() => {
      onRefresh();
      setEditMode(null);
    });
  };

  const handleDirectorInfoEdit = useCallback(
    (input: UpdateCustomerDirectorInfoInput) => {
      if (!limitRequest?.company?.id) {
        return;
      }
      if (customerDirectorInfoData?.id) {
        return updateDirectorInfo({
          id: customerDirectorInfoData.id,
          input,
        }).then(refreshCustomerDirectorInfo);
      }
      createDirectorInfo({
        input: {
          ...input,
          customerId: limitRequest.company.id,
        },
      }).then(refreshCustomerDirectorInfo);
    },
    [
      customerDirectorInfoData,
      limitRequest,
      updateDirectorInfo,
      createDirectorInfo,
      refreshCustomerDirectorInfo,
    ]
  );

  const editLimitRequestCompanyInfo = useEditLimitRequestCompanyInfo({
    limitRequest,
    cities,
    companies,
    countries,
    onSave: handleSave,
    onCancel: handleCancel,
    onChange: handleValueChange,
  });

  return (
    <Flex direction="column" gap={32}>
      <Flex direction="column" gap={20}>
        {editMode === EditMode.CompanyInfo && (
          <>{editLimitRequestCompanyInfo}</>
        )}
        {editMode === EditMode.DirectorInCharge && (
          <DirectorInfoForm
            value={customerDirectorInfoData}
            onCancel={handleCancel}
            onSubmit={handleDirectorInfoEdit}
          />
        )}
        {!editMode && (
          <>
            {hasEditAccess && (
              <Button
                variant="filledGolden"
                leftIcon={<SvgEdit />}
                uppercase
                loading={isFetching}
                onClick={() => handleEdit(EditMode.CompanyInfo)}
              >
                edit
              </Button>
            )}
            <SimpleGrid
              cols={1}
              breakpoints={[
                { minWidth: "sm", cols: 2 },
                { minWidth: "lg", cols: 3 },
              ]}
              spacing={20}
              verticalSpacing={20}
            >
              <MultiFieldCard
                label="Company Info"
                variant="blue"
                loading={isFetching}
                items={companyInfo}
              />
              <MultiFieldCard
                label="Company Address"
                variant="blue"
                loading={isFetching}
                items={companyAddress}
              />
            </SimpleGrid>
          </>
        )}
      </Flex>
      {!editMode && (
        <Flex direction="column" gap={20}>
          {hasEditAccess && (
            <Button
              variant="filledGolden"
              leftIcon={<SvgEdit />}
              uppercase
              loading={fetchingCustomerDirectorInfo}
              onClick={() => handleEdit(EditMode.DirectorInCharge)}
            >
              edit
            </Button>
          )}
          <SimpleGrid
            cols={1}
            breakpoints={[
              { minWidth: "sm", cols: 2 },
              { minWidth: "lg", cols: 3 },
            ]}
            spacing={20}
            verticalSpacing={20}
          >
            <MultiFieldCard
              label="Director In Charge Info"
              variant="blue"
              loading={fetchingCustomerDirectorInfo}
              items={directorInfo}
            />
          </SimpleGrid>
        </Flex>
      )}
    </Flex>
  );
};

export default CompanyInfo;
