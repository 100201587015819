import { useQuery } from "urql";

import {
  AiPromptResultFilter,
  AiPromptResultOrderBy,
  GetAiPromptResultsDocument,
} from "../../../graphql/generated";
import { getApiPaginationData } from "../../../utils/api";
import { ApiHookProps } from "../types.ts";

export const useAIPromptResults = ({
  filter,
  orderBy,
  pause,
  page,
}: ApiHookProps<AiPromptResultFilter, AiPromptResultOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetAiPromptResultsDocument, variables, pause });
};
