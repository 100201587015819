import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Client, fetchExchange, Provider as UrqlProvider } from "urql";

import { HasAccessGuard } from "./components/Permissions/HasAccessGuard.tsx";
import appConfig from "./config.ts";
import { AppRoute } from "./constants";
import { RolePermission } from "./graphql/generated.ts";
import Page404 from "./pages/404/Page404.tsx";
import ActivityManagementPage from "./pages/ActivityManagement/ActivityManagement.tsx";
import ActivityManagementDetailPage from "./pages/ActivityManagementDetail/ActivityManagementDetail.tsx";
import { AIAutomationPage } from "./pages/AIAutomation/AIAutomationPage.tsx";
import { AIResultPage } from "./pages/AIResult/AIResultPage.tsx";
import ClikDataPage from "./pages/ClikData/ClikDataPage.tsx";
import ClikDataDetailsPage from "./pages/ClikDataDetails/ClikDataDetailsPage.tsx";
import ClikResultPage from "./pages/ClikResult/ClikResultPage.tsx";
import CollectionDashboardPage from "./pages/CollectionDashboard/CollectionDashboardPage.tsx";
import CreditUnderwritingPage from "./pages/CreditUnderwriting/CreditUnderwritingPage.tsx";
import CustomerDatabasePage from "./pages/CustomerDatabase/CustomerDatabasePage.tsx";
import EmployeeCandidatePage from "./pages/EmployeeCandidateRequest/EmployeeCandidate.tsx";
import EmployeeCandidateRequestCreate from "./pages/EmployeeCandidateRequestCreate/EmployeeCandidateRequestCreate.tsx";
import LateFeeCalculatorPage from "./pages/LateFeeCalculator/LateFeeCalculatorPage.tsx";
import LimitRequestCreatePage from "./pages/LimitRequestCreate/LimitRequestCreatePage.tsx";
import LimitRequestDetailPage from "./pages/LimitRequestDetail/LimitRequestDetailPage.tsx";
import LoginAccessDeniedPage from "./pages/Login/LoginAccessDeniedPage.tsx";
import LoginPage from "./pages/Login/LoginPage.tsx";
import { MainPage } from "./pages/MainPage.tsx";
import PaymasterAnalysisPage from "./pages/PaymasterAnalysis/PaymasterAnalysisPage.tsx";
import PromptEditorPage from "./pages/PromptEditor/PromptEditorPage.tsx";
import ReviewPage from "./pages/Review/ReviewPage";
import RiskFunnelDashboardPage from "./pages/RiskFunnelDashboard/RiskFunnelDashboardPage.tsx";
import RiskMonitoringPage from "./pages/RiskMonitoring/RiskMonitoringPage.tsx";
import RiskReviewPage from "./pages/RiskReview/RiskReviewPage.tsx";
import UserAccessSettingPage from "./pages/UserAccessSetting/UserAccessSettingPage.tsx";
import { AuthGuard } from "./providers/AuthGuard.tsx";
import { CurrentUserProvider } from "./providers/CurrentUserProvider.tsx";
import { XRayProvider } from "./providers/XRayProvider.tsx";
import { theme } from "./utils/mantine/theme";

const router = createBrowserRouter(
  [
    {
      path: AppRoute.Home,
      element: (
        <AuthGuard>
          <MainPage />
        </AuthGuard>
      ),
    },
    {
      path: AppRoute.Login,
      element: <LoginPage />,
    },
    {
      path: AppRoute.AccessDenied,
      element: <LoginAccessDeniedPage />,
    },
    {
      path: AppRoute.UserAccessSetting,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewUserAccessSetting}>
            <UserAccessSettingPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: AppRoute.ActivityManagement,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewActivityManagement}>
            <ActivityManagementPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.ActivityManagement}/:customerId`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewActivityManagement}>
            <ActivityManagementDetailPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.LateFeeCalculator}`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewActivityManagement}>
            <LateFeeCalculatorPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: AppRoute.ReviewAndApproval,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewReviewApproval}>
            <ReviewPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: AppRoute.CollectionDashboard,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewCollection}>
            <CollectionDashboardPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: AppRoute.LimitRequestCreate,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanAddLimitRequest}>
            <LimitRequestCreatePage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: AppRoute.ClikData,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewClikData}>
            <ClikDataPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.ClikData}/:id`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewClikData}>
            <ClikDataDetailsPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: AppRoute.EmployeeCandidate,
      element: (
        <AuthGuard>
          <HasAccessGuard
            permission={RolePermission.CanViewEmployeeCandidateClik}
          >
            <EmployeeCandidatePage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: AppRoute.EmployeeCandidateCreate,
      element: (
        <AuthGuard>
          <HasAccessGuard
            permission={RolePermission.CanEditEmployeeCandidateClik}
          >
            <EmployeeCandidateRequestCreate />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.ClikResult}/:id`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewClikResult}>
            <ClikResultPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.LimitRequest}/:id`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewBuyerInfoLimitInfo}>
            <LimitRequestDetailPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: AppRoute.CreditUnderwriting,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewCreditUnderwriting}>
            <CreditUnderwritingPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.RiskFunnelDashboard}`,
      element: (
        <AuthGuard>
          <HasAccessGuard
            permission={RolePermission.CanViewRiskFunnelDashboard}
          >
            <RiskFunnelDashboardPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.RiskMonitoring}`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewRiskMonitoring}>
            <RiskMonitoringPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.RiskReview}`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewRiskReview}>
            <RiskReviewPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.Customers}`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewCustomerDatabase}>
            <CustomerDatabasePage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.PropmtEditor}`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewPrompt}>
            <PromptEditorPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.RunAIAutomation}`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewAiAutomation}>
            <AIAutomationPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.AIResult}`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewAiAutomation}>
            <AIResultPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: `${AppRoute.PaymasterAnalysis}`,
      element: (
        <AuthGuard>
          <HasAccessGuard permission={RolePermission.CanViewPaymaster}>
            <PaymasterAnalysisPage />
          </HasAccessGuard>
        </AuthGuard>
      ),
    },
    {
      path: "*",
      element: <Page404 />,
    },
  ],
  { basename: AppRoute.Home }
);

export default function App() {
  const client = new Client({
    url: `${appConfig.apiUrl}/graphql`,
    fetchOptions: { credentials: "include" },
    exchanges: [fetchExchange],
  });

  return (
    <UrqlProvider value={client}>
      <CurrentUserProvider>
        <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
          <XRayProvider>
            <Notifications position="top-right" style={{ zIndex: 4000 }} />
            <RouterProvider router={router} />
          </XRayProvider>
        </MantineProvider>
      </CurrentUserProvider>
    </UrqlProvider>
  );
}
