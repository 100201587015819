import {
  Button,
  Flex,
  MultiSelect,
  Select,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal.tsx";
import SvgPlus from "../../components/Icons/Plus.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Table from "../../components/Table/Table.tsx";
import { SortDirection } from "../../components/Table/types.ts";
import Title from "../../components/Title/Title.tsx";
import {
  CreatePaymasterInput,
  OrderBy,
  PaymasterOrderBy,
  RolePermission,
  UpdatePaymasterInput,
} from "../../graphql/generated.ts";
import { useCompanyOptions } from "../../hooks/api/customer/useCompanyOptions.ts";
import { useCreatePaymaster } from "../../hooks/api/paymaster/useCreatePaymaster.ts";
import { useDeletePaymaster } from "../../hooks/api/paymaster/useDeletePaymaster.ts";
import { usePaymasters } from "../../hooks/api/paymaster/usePaymasters.ts";
import { useUpdatePaymaster } from "../../hooks/api/paymaster/useUpdatePaymaster.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../providers/CurrentUserProvider.tsx";
import { paymasterFinalScoreOptions } from "../../types/paymaster.ts";
import { getCompanyOptions } from "../../utils/company.ts";
import { getPagesCount } from "../../utils/pagination.ts";
import { hasPermission } from "../../utils/user.ts";
import { CreatePaymasterModal } from "./CreatePaymasterModal.tsx";
import { usePaymasterTableData } from "./hooks/usePaymasterTableData.tsx";
import { PaymasterFiltersValue } from "./types.ts";
import { getPaymasterApiFilter, getPaymasterApiSort } from "./utils.ts";

const PaymasterAnalysisPage = () => {
  const { user } = useCurrentUserContext();

  const canEdit = hasPermission(user, RolePermission.CanEditPaymaster);

  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<PaymasterOrderBy>({
    id: OrderBy.Desc,
  });
  const [filter, setFilter] = useState<PaymasterFiltersValue>({});
  const [paymasterName, setPaymasterName] = useDebouncedState("", 500);
  const apiFilter = getPaymasterApiFilter({ ...filter, name: paymasterName });

  const [
    createModalOpened,
    { open: openCreateModal, close: closeCreateModal },
  ] = useDisclosure(false);
  const [
    deleteConfirmationModalOpened,
    { open: openDeleteConfirmationModal, close: closeDeleteConfirmationModal },
  ] = useDisclosure(false);

  const [{ data, fetching }, refresh] = usePaymasters({
    filter: apiFilter,
    orderBy,
    page,
  });
  const paymasters = data?.paymasters?.data;

  const pageCount = getPagesCount(data?.paymasters?.total);

  const [{ data: customerOptionsResponse, fetching: customerOptionsFetching }] =
    useCompanyOptions();
  const customerOptions = customerOptionsResponse?.customers.data;

  const [, updatePaymaster] = useMutationNotificationWrapper(
    useUpdatePaymaster(),
    {
      success: {
        message: "Paymaster has been successfully updated.",
      },
    }
  );

  const [selectedPaymasterId, setSelectedPaymasterId] = useState<number>();

  const [, deletePaymaster] = useMutationNotificationWrapper(
    useDeletePaymaster(),
    {
      success: {
        message: "Paymaster has been successfully deleted.",
      },
    }
  );

  const handleUpdatePaymaster = useCallback(
    (id: number, input: UpdatePaymasterInput) => {
      updatePaymaster({ id, input }).then(refresh);
    },
    [refresh, updatePaymaster]
  );

  const handleOpenDeleteConfirmationModal = useCallback(
    (id: number) => {
      setSelectedPaymasterId(id);
      openDeleteConfirmationModal();
    },
    [openDeleteConfirmationModal]
  );

  const [columns, rows] = usePaymasterTableData({
    data: paymasters,
    onInlineEdit: handleUpdatePaymaster,
    onDelete: handleOpenDeleteConfirmationModal,
  });

  const [, createPaymaster] = useMutationNotificationWrapper(
    useCreatePaymaster(),
    {
      success: {
        message: "Paymaster has been successfully created.",
      },
    }
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getPaymasterApiSort(sort));
    },
    [setOrderBy]
  );

  const handleFilterChange = useCallback(
    (value: Partial<PaymasterFiltersValue>) => {
      setFilter((prevFilter) => ({
        ...prevFilter,
        ...value,
      }));
    },
    [setFilter]
  );

  const handleCreatePaymaster = useCallback(
    (input: CreatePaymasterInput) => {
      createPaymaster({ input }).then(refresh);
    },
    [createPaymaster, refresh]
  );

  const handleDeletePaymaster = useCallback(() => {
    if (!selectedPaymasterId) {
      closeDeleteConfirmationModal();
      return;
    }
    deletePaymaster({ id: selectedPaymasterId })
      .then(refresh)
      .finally(() => {
        setSelectedPaymasterId(undefined);
        closeDeleteConfirmationModal();
      });
  }, [
    closeDeleteConfirmationModal,
    deletePaymaster,
    refresh,
    selectedPaymasterId,
  ]);

  return (
    <>
      <Layout>
        <Flex direction="column" gap={{ base: 16, sm: 24 }}>
          <Flex direction="column" gap={16}>
            <Title size="h1">Paymaster Analysis</Title>
            {canEdit && (
              <Button
                uppercase
                variant="filledGolden"
                leftIcon={<SvgPlus />}
                loading={customerOptionsFetching}
                onClick={openCreateModal}
              >
                Add
              </Button>
            )}
            <SimpleGrid
              w="100%"
              cols={3}
              breakpoints={[
                { maxWidth: "sm", cols: 2 },
                { maxWidth: "lg", cols: 3 },
              ]}
              spacing={20}
              verticalSpacing={12}
            >
              <MultiSelect
                searchable
                clearable
                size="m"
                placeholder="Customer Name"
                data={getCompanyOptions(customerOptions)}
                onChange={(customerId) => handleFilterChange({ customerId })}
              />
              <TextInput
                size="m"
                placeholder="Paymaster Name"
                onChange={(event) => {
                  setPaymasterName(event.target.value);
                }}
              />
              <Select
                placeholder="Final Score"
                size="m"
                clearable
                data={paymasterFinalScoreOptions}
                onChange={(finalScore) => handleFilterChange({ finalScore })}
              />
            </SimpleGrid>
          </Flex>

          <Table
            columns={columns}
            rows={rows}
            verticalAlignment="center"
            defaultSort={{
              columnAccessor: "createdAt",
              direction: SortDirection.asc,
            }}
            loading={fetching}
            pagination={{
              pageCount: pageCount,
              page: page,
              onPageChange: handlePageChange,
            }}
            onSortChange={handleSortChange}
          />
        </Flex>
        {createModalOpened && (
          <CreatePaymasterModal
            customers={customerOptions || []}
            opened={true}
            onClose={closeCreateModal}
            onSubmit={handleCreatePaymaster}
          />
        )}
        {deleteConfirmationModalOpened && (
          <ConfirmationModal
            opened={true}
            message={"Are you sure you want to delete this data?"}
            onConfirm={handleDeletePaymaster}
            onClose={closeDeleteConfirmationModal}
          />
        )}
      </Layout>
    </>
  );
};

export default PaymasterAnalysisPage;
