import { Select } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../../components/Table/types";
import {
  RolePermission,
  UpdateRrAssignedInput,
} from "../../../../graphql/generated.ts";
import { useCurrentUserContext } from "../../../../providers/CurrentUserProvider.tsx";
import { LimitRequest } from "../../../../types/limitRequest/limitRequest.ts";
import { UserOption } from "../../../../types/user.ts";
import { toLocalDate } from "../../../../utils/date";
import { toLocalNumber, toPrice } from "../../../../utils/number";
import { toHumanReadable } from "../../../../utils/string";
import {
  getUserDisplayName,
  getUserOptions,
  hasPermission,
} from "../../../../utils/user.ts";

interface Props {
  assignees: UserOption[] | null | undefined;
  data: LimitRequest[] | null | undefined;
  onEditRRAssigned: (id: number, input: UpdateRrAssignedInput) => void;
}

export const useNewCURecommendationTableData = ({
  data,
  assignees,
  onEditRRAssigned,
}: Props): TableData => {
  const { user } = useCurrentUserContext();

  const hasEditAccess = hasPermission(user, RolePermission.CanEditRiskReview);

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "cuResultDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CreditUnderwriting",
            fieldName: "analysisResultDate",
          }}
        >
          CU Result Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitName",
          }}
        >
          Limit Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "companyName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "name",
          }}
        >
          Company Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "salesName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesName",
          }}
        >
          Sales Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesTeamName",
          }}
        >
          Sales Team
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },

    {
      accessor: "cuLimitRecommendation",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CreditUnderwriting",
            fieldName: "limitRecommendation",
          }}
        >
          CU Limit Recommendation
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "cuTenorRecommendation",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CreditUnderwriting",
            fieldName: "tenorRecommendation",
          }}
        >
          CU Tenor Recommendation
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "cuInsuranceLimit",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CreditUnderwriting",
            fieldName: "insuranceLimitApproved",
          }}
        >
          Insurance Limit
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitRequestType",
          }}
        >
          Limit Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "cuAssignedName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "cuAssignedId",
          }}
        >
          CU Assigned
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "rrAssignedName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "rrAssignedId",
          }}
        >
          RR Assigned
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "requestStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "requestStatus",
          }}
        >
          Request Status
        </DatabaseMetadataPopover>
      ),
    },
  ];

  const handleOnClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    //  stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();
  }, []);

  const handleEditRRAssigned = useCallback(
    (id: number, rrAssignedId: number) => {
      onEditRRAssigned(id, { rrAssignedId, rrAssignedDate: new Date() });
    },
    [onEditRRAssigned]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        cuResultDate:
          item?.creditUnderwriting?.analysisResultDate &&
          toLocalDate(item.creditUnderwriting.analysisResultDate),
        limitName: item.limitName,
        companyName: item.company.name,
        salesName: item.company.salesName,
        salesTeamName: item.company.salesTeamName,
        cuLimitRecommendation: toPrice(
          item?.creditUnderwriting?.limitRecommendation
        ),
        cuTenorRecommendation: toLocalNumber(
          item?.creditUnderwriting?.tenorRecommendation
        ),
        cuInsuranceLimit: toPrice(
          item?.creditUnderwriting?.insuranceLimitApproved
        ),
        limitType:
          item?.limitRequestType && toHumanReadable(item.limitRequestType),
        cuAssignedName: item?.cuAssigned && getUserDisplayName(item.cuAssigned),
        rrAssignedName: hasEditAccess ? (
          <Select
            data={getUserOptions(assignees)}
            value={item.rrAssigned?.id.toString()}
            withinPortal
            zIndex={100}
            size="s"
            onChange={(value) => handleEditRRAssigned(item.id, Number(value))}
            onClick={(event) => handleOnClick(event)}
          />
        ) : (
          getUserDisplayName(item.rrAssigned)
        ),
        requestStatus: toHumanReadable(item.requestStatus),
      };
    });
  }, [data, hasEditAccess, assignees, handleOnClick, handleEditRRAssigned]);

  return [columns, rows];
};
