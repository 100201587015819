import {
  Accordion,
  Box,
  Card,
  Divider,
  Flex,
  MediaQuery,
  Text,
} from "@mantine/core";
import classNames from "classnames";
import chunk from "lodash/chunk";
import { isValidElement, ReactNode, useCallback, useMemo } from "react";

import { ThemeGradientBackground } from "../../../utils/mantine/themeGradientBackground.ts";
import { Loader } from "../../Loader.tsx";
import { CardEmptyValue } from "../constants.tsx";
import { CardContent } from "../types.ts";
import { isCardContentEmpty } from "../utils.tsx";
import { useStyles } from "./MultiFieldCard.styles.tsx";

interface NestedCardItem {
  label: string | ReactNode;
  content: CardContent;
}

export interface MultiFieldCardItem {
  label: string | ReactNode;
  content: CardContent;
  nestedContent?: NestedCardItem[][];
  openNestedContent?: boolean;
  isColumn?: boolean;
}

export interface MultiFieldCardProps {
  label?: string | ReactNode;
  items: MultiFieldCardItem[];
  variant?: ThemeGradientBackground;
  mode?: "simple" | "accordion";
  loading?: boolean;
  cardControl?: ReactNode;
  customVariant?: ReactNode;
  cols?: 1 | 2 | 3;
  cardFooterContent?: ReactNode;
  disabled?: boolean;
}

export default function MultiFieldCard({
  items,
  label,
  variant = "golden",
  mode = "simple",
  loading = false,
  cardControl,
  customVariant,
  cols = 1,
  cardFooterContent,
  disabled,
}: MultiFieldCardProps) {
  const { classes } = useStyles({ variant: disabled ? "disabled" : variant });

  const getItemNode = useCallback(
    (content: CardContent) => {
      if (isCardContentEmpty(content)) {
        return CardEmptyValue;
      }
      return isValidElement(content) ? (
        <Flex className={classes.itemContentText}>{content}</Flex>
      ) : (
        <Text className={classes.itemContentText}>{content}</Text>
      );
    },
    [classes.itemContentText]
  );

  const renderCardColumn = useCallback(
    (items: MultiFieldCardItem[], key: number) => (
      <Flex direction="column" w="100%" gap={3} key={key}>
        {items.map((item, index) => (
          <Flex key={index} direction="column">
            <Flex
              justify="space-between"
              gap={item.isColumn ? 4 : 24}
              className={classNames(item.isColumn && classes.itemColumn)}
            >
              <Text className={classes.itemLabel}>{item.label}</Text>
              {getItemNode(item.content)}
            </Flex>
            {item.nestedContent && item.openNestedContent && (
              <Flex direction="column" gap={4}>
                {item.nestedContent.map((nestedItem, index) => (
                  <Flex
                    key={index}
                    direction="column"
                    className={classes.nestedItemWrapper}
                  >
                    {nestedItem?.map((nestedCard, index) => (
                      <Flex key={index} justify="space-between" gap={24}>
                        <Text>{nestedCard.label}</Text>
                        {getItemNode(nestedCard.content)}
                      </Flex>
                    ))}
                  </Flex>
                ))}
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
    ),
    [classes, getItemNode]
  );

  const cardContent = useMemo(() => {
    const itemsCountPerCol = Math.ceil(items.length / cols);
    const renderedColumns = chunk(items, itemsCountPerCol).map((col, index) => {
      if (index === cols - 1) {
        return renderCardColumn(col, index);
      }
      return (
        <>
          {renderCardColumn(col, index)}
          <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
            <Divider orientation="horizontal" w={1} />
          </MediaQuery>
        </>
      );
    });
    return (
      <Flex gap={30} w="100%" className={classes.colsWrapper}>
        {renderedColumns}
      </Flex>
    );
  }, [items, cols, renderCardColumn, classes.colsWrapper]);

  if (mode === "accordion") {
    return (
      <Accordion className={classes.accordion} chevronPosition="left">
        <Accordion.Item value="multiCardItem">
          <Accordion.Control>
            <Flex justify="space-between">
              {customVariant ? (
                customVariant
              ) : (
                <Flex gap={12} align="center">
                  <Box className={classes.pin} />
                  <Text className={classes.label}>{label}</Text>
                </Flex>
              )}
              {cardControl}
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            {cardContent}
            {cardFooterContent}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    );
  }

  return (
    <Card padding={20} radius={4} className={classes.wrapper}>
      <Flex direction="column" h="100%" gap={12} pos="relative">
        <Flex justify="space-between">
          {customVariant ? (
            customVariant
          ) : (
            <Flex gap={12} align="center">
              <Box className={classes.pin} />
              <Text className={classes.label}>{label}</Text>
            </Flex>
          )}
          {cardControl}
        </Flex>
        <Divider />
        {loading && <Loader mih="100%" />}
        {cardContent}
        {cardFooterContent}
      </Flex>
    </Card>
  );
}
