import { Flex, Select, Textarea, TextInput } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import ActionIcon from "../../../components/ActionIcon/ActionIcon.tsx";
import { DatabaseMetadataPopover } from "../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SvgDelete from "../../../components/Icons/Delete.tsx";
import Spoiler from "../../../components/Spoiler/Spoiler.tsx";
import { TableData, TableRow } from "../../../components/Table/types.ts";
import {
  PaymasterFinalScore,
  PaymasterHistoricalCreditControl,
  PaymasterHistoricalPayment,
  UpdatePaymasterInput,
  UserRole,
} from "../../../graphql/generated.ts";
import { useCurrentUserContext } from "../../../providers/CurrentUserProvider.tsx";
import {
  Paymaster,
  paymasterCreditControlOptions,
  paymasterFinalScoreOptions,
  paymasterHistoricalPaymentOptions,
  toHumanReadablePaymasterHistoricalPayment,
} from "../../../types/paymaster.ts";
import { toHumanReadable } from "../../../utils/string.ts";

interface Props {
  data: Paymaster[] | null | undefined;
  onInlineEdit: (id: number, input: UpdatePaymasterInput) => void;
  onDelete: (id: number) => void;
}

export const usePaymasterTableData = ({
  data,
  onInlineEdit,
  onDelete,
}: Props): TableData => {
  const { user } = useCurrentUserContext();

  const isAdmin =
    user && [UserRole.Admin, UserRole.SuperAdmin].includes(user.role);
  const isRMonTeam =
    user && [UserRole.RmonManager, UserRole.RmonOfficer].includes(user.role);
  const isRRTeam =
    user && [UserRole.RrOfficer, UserRole.RrManager].includes(user.role);
  const isCollectionTeam =
    user &&
    [UserRole.CollectionManager, UserRole.CollectionOfficer].includes(
      user.role
    );

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "customerName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Paymaster",
            fieldName: "customer",
          }}
        >
          Customer Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "historicalPayment",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Paymaster",
            fieldName: "historicalPayment",
          }}
        >
          Historical Payment Customer to bababos
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "name",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Paymaster",
            fieldName: "name",
          }}
        >
          Paymaster Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "historicalPaymasterRMon",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Paymaster",
            fieldName: "historicalPaymasterRMon",
          }}
        >
          Historical Paymaster (Remarks from RMon)
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "historicalCreditControl",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Paymaster",
            fieldName: "historicalCreditControl",
          }}
        >
          Historical Credit Control (Remarks from Risk Assurance)
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "finalScore",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Paymaster",
            fieldName: "finalScore",
          }}
        >
          Final Score
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "notesRMon",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Paymaster",
            fieldName: "notesRMon",
          }}
        >
          Remarks from RMon
        </DatabaseMetadataPopover>
      ),
      width: 500,
    },
    {
      accessor: "actions",
      title: "Actions",
      hidden: !(isAdmin || isRMonTeam),
    },
  ];

  const handleInlineEdit = useCallback(
    (id: number, input: UpdatePaymasterInput) => {
      onInlineEdit(id, input);
    },
    [onInlineEdit]
  );

  const renderHistoricalPayment = useCallback(
    (item: Paymaster) => {
      const canEdit = isAdmin || isRMonTeam;
      if (canEdit) {
        return (
          <Select
            placeholder="Select Historical Payment"
            size="sm"
            data={paymasterHistoricalPaymentOptions}
            value={item.historicalPayment}
            onChange={(value) =>
              handleInlineEdit(item.id, {
                historicalPayment: value as PaymasterHistoricalPayment,
              })
            }
          />
        );
      }
      return (
        item.historicalPayment &&
        toHumanReadablePaymasterHistoricalPayment(item.historicalPayment)
      );
    },
    [handleInlineEdit, isAdmin, isRMonTeam]
  );

  const renderHistoricalPaymasterRMon = useCallback(
    (item: Paymaster) => {
      const canEdit = isAdmin || isRMonTeam;
      if (canEdit) {
        return (
          <Select
            placeholder="Select Historical Payment"
            size="sm"
            data={paymasterHistoricalPaymentOptions}
            value={item.historicalPaymasterRMon}
            onChange={(value) =>
              handleInlineEdit(item.id, {
                historicalPaymasterRMon: value as PaymasterHistoricalPayment,
              })
            }
          />
        );
      }
      return (
        item.historicalPaymasterRMon &&
        toHumanReadablePaymasterHistoricalPayment(item.historicalPaymasterRMon)
      );
    },
    [handleInlineEdit, isAdmin, isRMonTeam]
  );

  const renderHistoricalCreditControl = useCallback(
    (item: Paymaster) => {
      const canEdit = isAdmin || isCollectionTeam;
      if (canEdit) {
        return (
          <Select
            placeholder="Select Historical Credit Control"
            size="sm"
            data={paymasterCreditControlOptions}
            value={item.historicalCreditControl}
            onChange={(value) =>
              handleInlineEdit(item.id, {
                historicalCreditControl:
                  value as PaymasterHistoricalCreditControl,
              })
            }
          />
        );
      }
      return (
        item.historicalCreditControl &&
        toHumanReadable(item.historicalCreditControl)
      );
    },
    [handleInlineEdit, isAdmin, isCollectionTeam]
  );

  const renderPaymasterName = useCallback(
    (item: Paymaster) => {
      const canEdit = isAdmin || isRRTeam;
      if (canEdit) {
        return (
          <TextInput
            placeholder="Enter Paymaster Name"
            size="sm"
            defaultValue={item.name ?? undefined}
            onBlur={(event) =>
              handleInlineEdit(item.id, { name: event.target.value })
            }
          />
        );
      }
      return item.name;
    },
    [handleInlineEdit, isAdmin, isRRTeam]
  );

  const renderFinalScore = useCallback(
    (item: Paymaster) => {
      const canEdit = isAdmin || isRRTeam;
      if (canEdit) {
        return (
          <Select
            placeholder="Select Final Score"
            size="sm"
            data={paymasterFinalScoreOptions}
            value={item.finalScore}
            onChange={(value) =>
              handleInlineEdit(item.id, {
                finalScore: value as PaymasterFinalScore,
              })
            }
          />
        );
      }
      return item.finalScore && toHumanReadable(item.finalScore);
    },
    [handleInlineEdit, isAdmin, isRRTeam]
  );

  const renderNotesRMon = useCallback(
    (item: Paymaster) => {
      const canEdit = isAdmin || isRMonTeam;
      if (canEdit) {
        return (
          <Textarea
            placeholder="Enter Notes"
            autosize
            size="sm"
            defaultValue={item.notesRMon ?? undefined}
            onBlur={(event) =>
              handleInlineEdit(item.id, {
                notesRMon: event.target.value,
              })
            }
          />
        );
      }
      return (
        item.notesRMon && (
          <Spoiler maxStringLength={500} text={item.notesRMon} />
        )
      );
    },
    [handleInlineEdit, isAdmin, isRMonTeam]
  );

  const getRow = useCallback(
    (item: Paymaster): TableRow => {
      return {
        id: item.id,
        customerName: item.customer.name,
        historicalPayment: renderHistoricalPayment(item),
        name: renderPaymasterName(item),
        historicalPaymasterRMon: renderHistoricalPaymasterRMon(item),
        historicalCreditControl: renderHistoricalCreditControl(item),
        finalScore: renderFinalScore(item),
        notesRMon: renderNotesRMon(item),
        actions: (
          <Flex gap={8}>
            <ActionIcon variant="error" onClick={() => onDelete(item.id)}>
              <SvgDelete />
            </ActionIcon>
          </Flex>
        ),
      };
    },
    [
      onDelete,
      renderFinalScore,
      renderHistoricalCreditControl,
      renderHistoricalPaymasterRMon,
      renderHistoricalPayment,
      renderNotesRMon,
      renderPaymasterName,
    ]
  );

  const rows = useMemo(() => {
    return data?.map(getRow);
  }, [data, getRow]);

  return [columns, rows];
};
