import { Button, Flex, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useCallback } from "react";

import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal.tsx";
import SectionTitle from "../../components/Section/SectionTitle/SectionTitle.tsx";
import { RolePermission } from "../../graphql/generated.ts";
import { useDeleteAIPrompt } from "../../hooks/api/ai/prompt/useDeleteAIPrompt.ts";
import { useUpdateAIPrompt } from "../../hooks/api/ai/prompt/useUpdateAIPrompt.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../providers/CurrentUserProvider.tsx";
import { AIPrompt } from "../../types/ai/prompt.ts";
import { hasPermission } from "../../utils/user.ts";

interface Props {
  prompt: AIPrompt;
  refresh: () => void;
}

export const PromptEditorItem = ({ prompt, refresh }: Props) => {
  const { user } = useCurrentUserContext();

  const canEdit = hasPermission(user, RolePermission.CanEditPrompt);

  const [, updatePrompt] = useMutationNotificationWrapper(useUpdateAIPrompt(), {
    success: {
      message: "Prompt has been successfully updated",
    },
  });

  const [, deletePrompt] = useMutationNotificationWrapper(useDeleteAIPrompt(), {
    success: {
      message: "Prompt has been successfully deleted",
    },
  });

  const [
    showDeleteConfirmationModal,
    { open: openDeleteConfirmationModal, close: closeDeleteConfirmationModal },
  ] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      prompt: prompt.prompt,
    },
    validateInputOnChange: true,
    clearInputErrorOnChange: true,
  });

  const handleUpdatePrompt = useCallback(() => {
    updatePrompt({ id: prompt.id, input: form.values }).then((data) => {
      if (!data.error) {
        refresh();
      }
    });
  }, [form.values, prompt.id, refresh, updatePrompt]);

  const handleDeletePrompt = useCallback(() => {
    deletePrompt({ id: prompt.id }).then((data) => {
      if (!data.error) {
        refresh();
      }
    });
  }, [deletePrompt, prompt.id, refresh]);

  return (
    <Flex gap={16} direction="column">
      <SectionTitle variant="blue">{prompt.title}</SectionTitle>
      <Textarea
        disabled={!canEdit}
        autosize
        required
        size="m"
        minRows={3}
        placeholder="Enter Prompt"
        {...form.getInputProps("prompt")}
      />
      {canEdit && (
        <Flex gap={16} direction="row-reverse">
          <Button
            variant="filledGolden"
            size="md"
            disabled={!form.isDirty() || !form.isValid()}
            onClick={handleUpdatePrompt}
          >
            Edit
          </Button>
          <Button
            variant="outlineGolden"
            size="md"
            onClick={openDeleteConfirmationModal}
          >
            Delete
          </Button>
        </Flex>
      )}
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          opened={true}
          message={"Are you sure you want to delete this prompt?"}
          onConfirm={handleDeletePrompt}
          onClose={closeDeleteConfirmationModal}
        />
      )}
    </Flex>
  );
};
