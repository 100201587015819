import { useQuery } from "urql";

import { GetInvoiceDocument } from "../../../graphql/generated";

interface Props {
  id: number | string | null | undefined;
}

export function useInvoice({ id }: Props) {
  return useQuery({
    query: GetInvoiceDocument,
    variables: { id: Number(id) },
    pause: !id,
  });
}
