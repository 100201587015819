import {
  Button,
  Flex,
  Input,
  MediaQuery,
  Modal,
  ScrollArea,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, matches, useForm } from "@mantine/form";
import { isNull } from "lodash";
import { useCallback } from "react";

import { FileResult } from "../../../graphql/generated.ts";
import { RawGroup } from "../../../types/limitRequest/group.ts";
import { phoneMatcher, toClikPhoneFormat } from "../../../utils/phone.ts";
import Dropzone from "../../Dropzone/Dropzone.tsx";

interface GroupFormProps {
  opened: boolean;
  value: RawGroup | null;
  disabledFields?: string[] | null;
  onClose: () => void;
  onSubmit: (value: RawGroup) => void;
}

export const GroupFormModal = ({
  opened,
  value,
  disabledFields,
  onClose,
  onSubmit,
}: GroupFormProps) => {
  const form = useForm({
    initialValues: {
      id: value?.id,
      name: value?.name,
      bussinessIdentifyNumber: value?.bussinessIdentifyNumber,
      phone: value?.phone,
      npwpFile: value?.npwpFile,
      registrationForm: value?.registrationForm,
    },
    validate: {
      name: matches(/^[A-Za-z\s]+$/, "Name must contain only letters"),
      phone: phoneMatcher,
      bussinessIdentifyNumber: isNotEmpty("Field is required"),
      npwpFile: isNotEmpty("Field is required"),
      registrationForm: isNotEmpty("Field is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleFileUploaded = (name: keyof RawGroup, files: FileResult[]) => {
    form.setValues({ [name]: files[0] });
  };

  const handleRemoveFile = (name: keyof RawGroup) => {
    form.setValues({ [name]: undefined });
  };

  const handleSubmit = useCallback(() => {
    if (!form.isValid() || !form.values.phone) {
      return;
    }
    onSubmit({
      ...form.values,
      phone: toClikPhoneFormat(form.values.phone),
    } as RawGroup);
    handleClose();
  }, [form, handleClose, onSubmit]);

  return (
    <Modal
      size="xl"
      opened={opened}
      title={isNull(value) ? "Add Group" : "Update Group"}
      scrollAreaComponent={ScrollArea.Autosize}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      onClose={handleClose}
    >
      <Flex direction="column" gap={20} pb={{ base: 84, sm: 0 }}>
        <TextInput
          required
          label="Group Name"
          placeholder="Enter Group Name"
          size="m"
          disabled={disabledFields?.includes("name")}
          {...form.getInputProps("name")}
        />
        <TextInput
          required
          label="Bussiness Identify Number"
          placeholder="Enter Bussiness Identify Number"
          size="m"
          {...form.getInputProps("bussinessIdentifyNumber")}
        />
        <TextInput
          required
          label="Phone"
          placeholder="Enter Phone"
          size="m"
          {...form.getInputProps("phone")}
        />
        <Input.Wrapper required label="NPWP File" size="m" w="100%">
          <Dropzone
            maxFiles={1}
            maxWidth="unset"
            value={form?.values?.npwpFile ? [form.values.npwpFile] : null}
            onUploadSuccess={(files: FileResult[]) =>
              handleFileUploaded("npwpFile", files)
            }
            onRemove={() => handleRemoveFile("npwpFile")}
          />
        </Input.Wrapper>
        <Input.Wrapper required label="Registration Form" size="m" w="100%">
          <Dropzone
            maxFiles={1}
            maxWidth="unset"
            value={
              form?.values?.registrationForm
                ? [form.values.registrationForm]
                : null
            }
            onUploadSuccess={(files: FileResult[]) =>
              handleFileUploaded("registrationForm", files)
            }
            onRemove={() => handleRemoveFile("registrationForm")}
          />
        </Input.Wrapper>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <Flex justify="flex-end" gap={20}>
            <Button
              uppercase
              variant="outlineBlue"
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              uppercase
              variant="filledBlue"
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              save
            </Button>
          </Flex>
        </MediaQuery>
      </Flex>
    </Modal>
  );
};
