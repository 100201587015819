import { isEmpty } from "lodash";
import isBoolean from "lodash/isBoolean";
import pickBy from "lodash/pickBy";
import { DataTableSortStatus } from "mantine-datatable";

import {
  InvoiceListFilter,
  StringFilterMode,
  // InvoiceOrderBy,
  TransactionHistoryFilter,
  TransactionHistoryOrderBy,
} from "../../../graphql/generated.ts";
import { getApiOrderBy } from "../../../utils/api.ts";
import { TransactionHistoryFiltersValue } from "./types.ts";

const getInvoiceApiFilter = (
  value: TransactionHistoryFiltersValue
): InvoiceListFilter | undefined => {
  let invoiceFilter = {};
  if (value.paymentStatus) {
    invoiceFilter = {
      ...invoiceFilter,
      paymentStatus: value.paymentStatus && { equals: value.paymentStatus },
    };
  }
  if (isBoolean(value.isRestructured)) {
    if (value.isRestructured) {
      invoiceFilter = { ...invoiceFilter, isRestructured: { equals: true } };
    } else {
      invoiceFilter = { ...invoiceFilter, isRestructured: { equals: false } };
    }
  }
  return isEmpty(invoiceFilter) ? undefined : { some: invoiceFilter };
};

export const getTransactionHistoryApiFilter = (
  value: TransactionHistoryFiltersValue
): TransactionHistoryFilter => {
  return pickBy({
    paymentType: value.paymentType && { equals: value.paymentType },
    salesName: value.salesName && { equals: value.salesName },
    salesTeam: value.salesTeam && { equals: value.salesTeam },
    hasException: isBoolean(value.hasException)
      ? { equals: value.hasException }
      : undefined,
    invoices: getInvoiceApiFilter(value),
    orderStatus: value.orderStatus && { equals: value.orderStatus },
    paymentMethod: value.paymentMethod && { equals: value.paymentMethod },
    quotationNo: value.quotationNo && {
      contains: value.quotationNo,
      mode: StringFilterMode.Insensitive,
    },
  });
};

export const getTransactionHistoryRequestApiSort = (
  tableSort: DataTableSortStatus
): TransactionHistoryOrderBy => {
  return getApiOrderBy(tableSort);
};
