import { Title as MantineTitle } from "@mantine/core";
import { TitleOrder, TitleSize } from "@mantine/core/lib/Title/Title";
import classNames from "classnames";
import { ReactNode } from "react";

import { useStyles } from "./Title.styles.ts";

interface TitleProps {
  children: ReactNode;
  size?: TitleSize;
  order?: TitleOrder;
  className?: string;
}

const Title = ({ children, size, order, className }: TitleProps) => {
  const { classes } = useStyles();

  return (
    <MantineTitle
      size={size}
      order={order}
      className={classNames(classes.title, className)}
    >
      {children}
    </MantineTitle>
  );
};

export default Title;
