import { MantineTheme, rem } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

const activeBadge = (theme: MantineTheme) => ({
  root: {
    background: "rgba(72, 150, 35, 0.10)",
    color: theme.colors.greenIce[3],
    fontSize: rem(12),
    fontWeight: 500,
    lineHeight: 1.3,
  },
});

const inactiveBadge = (theme: MantineTheme) => ({
  root: {
    background: "rgba(176, 182, 189, 0.10)",
    color: theme.colors.grayIce[0],
  },
});

const updatedBadge = (theme: MantineTheme) => ({
  root: {
    background: theme.colors.grayIce[2],
    color: theme.colors.midnightBlue[0],
  },
});

const goldenBadge = (theme: MantineTheme) => ({
  root: {
    background: `linear-gradient(180deg, ${theme.colors.golden[0]} 12.5%, ${theme.colors.bronze[0]} 220.45%)`,
    color: theme.colors.white[0],
  },
});

const blueBadge = (theme: MantineTheme) => ({
  root: {
    background: `linear-gradient(357deg, ${theme.colors.dusk[0]} 8.1%, ${theme.colors.dusk[1]} 51.61%, ${theme.colors.dusk[2]} 64.51%, ${theme.colors.dusk[3]} 85.59%)`,
    color: theme.colors.white[0],
  },
});

const goldenOutlineBadge = (theme: MantineTheme) => ({
  root: {
    color: theme.colors.golden[0],
    background: theme.colors.white[0],
    border: `1px solid ${theme.colors.golden[0]}`,
  },
});

export default function badgeTheme(): ThemeComponent {
  return {
    variants: {
      active: activeBadge,
      inactive: inactiveBadge,
      updated: updatedBadge,
      goldenFilled: goldenBadge,
      goldenOutline: goldenOutlineBadge,
      blueFilled: blueBadge,
    },
    sizes: {
      s: () => ({
        root: {
          height: rem(20),
          padding: `${rem(2)} ${rem(8)}`,
          borderRadius: rem(2),
          textTransform: "capitalize",
        },
      }),
      sm: () => ({
        root: {
          height: rem(32),
          minWidth: rem(150),
          width: "fit-content",
          padding: `${rem(8)} ${rem(20)}`,
          borderRadius: rem(4),
          textTransform: "uppercase",
          fontWeight: 600,
          fontSize: rem(12),
          lineHeight: rem(18),
        },
      }),
      m: () => ({
        root: {
          height: rem(44),
          padding: `${rem(8)} ${rem(20)}`,
          borderRadius: rem(4),
          textTransform: "uppercase",
          fontWeight: 600,
          fontSize: rem(16),
          lineHeight: rem(24),
        },
      }),
    },
  };
}
