import { MantineTheme } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

const goldenAccordion = (theme: MantineTheme) => ({
  control: {
    background: `linear-gradient(180deg, ${theme.colors.golden[0]} 12.5%, ${theme.colors.bronze[0]} 220.45%)`,
    color: theme.colors.white[0],
  },
});

const blueAccordion = (theme: MantineTheme) => ({
  control: {
    background: `linear-gradient(357deg, ${theme.colors.dusk[0]} 8.1%, ${theme.colors.dusk[1]} 51.61%, ${theme.colors.dusk[2]} 64.51%, ${theme.colors.dusk[3]} 85.59%)`,
    color: theme.colors.white[0],
  },
});

const goldenOutlineAccordion = (theme: MantineTheme) => ({
  control: {
    color: theme.colors.golden[0],
    background: theme.colors.white[0],
    border: `1px solid ${theme.colors.golden[0]}`,
  },
});

export default function accordionTheme(): ThemeComponent {
  return {
    variants: {
      goldenFilled: goldenAccordion,
      goldenOutline: goldenOutlineAccordion,
      blueFilled: blueAccordion,
    },
  };
}
