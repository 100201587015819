import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  link: {
    color: theme.colors.midnightBlue[0],
    fontSize: rem(14),
    fontWeight: 500,
    lineHeight: 1.3,
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
