import pickBy from "lodash/pickBy";

import { InvoiceFilter, StringFilterMode } from "../../../graphql/generated.ts";
import { InvoicesHistoryFiltersValue } from "./types.ts";

export const getInvoicesHistoryApiFilter = (
  value: InvoicesHistoryFiltersValue
): InvoiceFilter => {
  return pickBy({
    paymentStatus: value.paymentStatus && { equals: value.paymentStatus },
    salesName: value.salesName && { equals: value.salesName },
    salesTeamName: value.salesTeamName && { equals: value.salesTeamName },
    saleOrderNumber: value.saleOrderNumber && {
      contains: value.saleOrderNumber,
      mode: StringFilterMode.Insensitive,
    },
    invoiceNumber: value.invoiceNumber && {
      contains: value.invoiceNumber,
      mode: StringFilterMode.Insensitive,
    },
    limitUsed: value.limitUsed && {
      contains: value.limitUsed,
      mode: StringFilterMode.Insensitive,
    },
  });
};
