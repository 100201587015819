import { PengurusGender } from "./graphql/generated";
import { toHumanReadable } from "./utils/string.ts";

export enum AppRoute {
  Home = "/",
  Login = "/login",
  AccessDenied = "/access-denied",
  UserAccessSetting = "/user-access-setting",
  ActivityManagement = "/activity-management",
  LateFeeCalculator = "/late-fee-calculator",
  CollectionDashboard = "/collection-dashboard",
  ReviewAndApproval = "/review-and-approval",
  LimitRequest = "/limit-request",
  LimitRequestCreate = `${AppRoute.LimitRequest}/create`,
  ClikData = "/clik-data",
  ClikResult = "/clik-result",
  EmployeeCandidate = "/employee-candidate",
  EmployeeCandidateCreate = `${AppRoute.EmployeeCandidate}/create`,
  RiskFunnelDashboard = "/risk-funnel-dashboard",
  CreditUnderwriting = "/credit-underwriting",
  RiskReview = "/risk-review",
  RiskMonitoring = "/risk-monitoring",
  Customers = "/customers",
  PropmtEditor = "/prompt-editor",
  RunAIAutomation = "/run-ai-automation",
  AIResult = "/ai-result-database",
  PaymasterAnalysis = "/paymaster-analysis",
}

export const CURRENCY = "IDR";

export const genderOptions = Object.values(PengurusGender).map((item) => ({
  value: item,
  label: toHumanReadable(item),
}));

export const boolSelectorOptions = [
  {
    value: "true",
    label: "True",
  },
  {
    value: "false",
    label: "False",
  },
];

export const yesNoOptions = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export const API_DATE_FORMAT = "YYYY-MM-DD";

export const LATE_CHARGE_FEE = 0.002;
