import { Box, Card, Divider, Flex, Text } from "@mantine/core";
import React, { ReactNode } from "react";

import { ThemeGradientBackground } from "../../../utils/mantine/themeGradientBackground.ts";
import { Loader } from "../../Loader.tsx";
import { CardEmptyValue } from "../constants.tsx";
import { CardContent } from "../types.ts";
import { isCardContentEmpty } from "../utils.tsx";
import { useStyles } from "./SingleFieldCard.styles.ts";

export interface SingleFieldCardProps {
  label: string | ReactNode;
  value: CardContent;
  variant?: ThemeGradientBackground;
  loading?: boolean;
}

const SingleFieldCard = ({
  label,
  value,
  variant = "golden",
  loading = false,
}: SingleFieldCardProps) => {
  const { classes } = useStyles({ variant });

  const getItemNode = (content: CardContent) => {
    if (isCardContentEmpty(content)) {
      return CardEmptyValue;
    }
    return React.isValidElement(content) ? (
      content
    ) : (
      <Text className={classes.value}>{content}</Text>
    );
  };

  return (
    <Card padding={20} radius={4} className={classes.wrapper}>
      <Flex direction="column" gap={12}>
        <Flex gap={12} align="center">
          <Box className={classes.pin} />
          <Text className={classes.label}>{label}</Text>
        </Flex>
        <Divider />
        {loading && <Loader size={80} mih={100} />}
        {!loading && getItemNode(value)}
      </Flex>
    </Card>
  );
};

export default SingleFieldCard;
