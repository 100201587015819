import { Flex, Image } from "@mantine/core";
import { SystemProp } from "@mantine/styles/lib/theme/types/MantineStyleSystem";
import { CSSProperties } from "react";

import loaderGif from "/loader.gif";

interface Props {
  mih?: SystemProp<CSSProperties["minHeight"]>;
  size?: number;
  position?: "absolute" | "relative";
}

export const Loader = ({ mih, position, size = 120 }: Props) => {
  return (
    <Flex
      pos="relative"
      align="center"
      justify="center"
      mih={mih}
      style={{ width: "100%", height: "100%", position }}
    >
      <Image width={size} height={size} src={loaderGif} alt="Loading..." />
    </Flex>
  );
};
