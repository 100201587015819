import { createStyles, MantineTheme, rem } from "@mantine/core";

import { themeGradientBackground } from "../../utils/mantine/themeGradientBackground.ts";

export const useStyles = createStyles(
  (
    theme: MantineTheme,
    {
      variant,
    }: { variant: "golden" | "blue" | "bronze" | "green" | "disabled" }
  ) => ({
    pin: {
      width: rem(12),
      height: rem(12),
      flexShrink: 0,
      ...themeGradientBackground(theme, variant),
    },
    label: {
      fontSize: rem(14),
      lineHeight: 1.5,
      textTransform: "capitalize",
      fontWeight: 600,
      color:
        variant === "disabled"
          ? theme.colors.grayIce[0]
          : theme.colors.midnightBlue[0],
    },
  })
);
