import pickBy from "lodash/pickBy";
import { DataTableSortStatus } from "mantine-datatable";

import {
  PaymasterFilter,
  PaymasterOrderBy,
  StringFilterMode,
} from "../../graphql/generated.ts";
import { getApiOrderBy } from "../../utils/api.ts";
import { PaymasterFiltersValue } from "./types.ts";

export const getPaymasterApiFilter = (
  value: PaymasterFiltersValue
): PaymasterFilter => {
  return pickBy({
    customerId: value.customerId?.length && {
      in: value.customerId.map(Number),
    },
    name: value.name && {
      contains: value.name,
      mode: StringFilterMode.Insensitive,
    },
    finalScore: value.finalScore && { equals: value.finalScore },
  });
};

export const getPaymasterApiSort = (
  tableSort: DataTableSortStatus
): PaymasterOrderBy => {
  const { customerId, ...apiSort } = getApiOrderBy(tableSort);
  return {
    ...apiSort,
    customer: customerId && { name: customerId },
  };
};
