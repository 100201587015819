import { createStyles, MantineTheme, rem } from "@mantine/core";

import { themeGradientBackground } from "../../../utils/mantine/themeGradientBackground.ts";

export const useStyles = createStyles(
  (
    theme: MantineTheme,
    {
      variant,
    }: { variant: "golden" | "blue" | "bronze" | "green" | "disabled" }
  ) => ({
    wrapper: {
      boxShadow: "0px 2px 19px 0px rgba(55, 65, 82, 0.10)",
      width: "100%",
      height: "100%",
      opacity: variant === "disabled" ? 0.8 : 1,
    },
    colsWrapper: {
      "@media (max-width: 74em)": {
        flexDirection: "column",
        gap: "unset",
      },
    },
    accordion: {
      boxShadow: "0px 2px 19px 0px rgba(55, 65, 82, 0.10)",
      ".mantine-Accordion-item": {
        borderBottom: "none",
      },
      ".mantine-Accordion-control": {
        borderBottom: "0.0625rem solid #ced4da",
      },
    },
    pin: {
      width: rem(12),
      height: rem(12),
      flexShrink: 0,
      ...themeGradientBackground(theme, variant),
    },
    label: {
      fontSize: rem(14),
      lineHeight: 1.5,
      textTransform: "capitalize",
      fontWeight: 600,
      color:
        variant === "disabled"
          ? theme.colors.grayIce[0]
          : theme.colors.midnightBlue[0],
    },
    itemColumn: {
      flexDirection: "column",
      alignItems: "start",
      gap: rem(4),
      width: "100%",
    },
    itemLabel: {
      fontSize: rem(14),
      lineHeight: 1.5,
      textTransform: "capitalize",
    },
    itemContentText: {
      fontSize: rem(14),
      fontWeight: 500,
      lineHeight: 1.3,
      wordBreak: "break-word",
      maxWidth: "70%",
      textAlign: "end",
      color:
        variant === "disabled"
          ? theme.colors.grayIce[0]
          : theme.colors.midnightBlue[0],
      a: {
        color:
          variant === "disabled"
            ? theme.colors.grayIce[0]
            : theme.colors.midnightBlue[0],
      },
    },
    nestedItemWrapper: {
      borderRadius: rem(4),
      padding: rem(12),
      border: `1px solid ${theme.colors.grayIce[0]}`,
      fontSize: rem(12),
      lineHeight: rem(18),
      textTransform: "capitalize",
      color: theme.colors.black[0],
    },
    nestedItemContent: {
      lineHeight: rem(15.6),
      fontWeight: 500,
      color: theme.colors.midnightBlue[0],
    },
  })
);
