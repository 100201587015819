import {
  Button,
  Flex,
  Modal,
  ScrollArea,
  Textarea,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import { CreateAiPromptInput } from "../../graphql/generated.ts";

interface Props {
  opened: boolean;
  onClose: () => void;
  onSubmit: (value: CreateAiPromptInput) => void;
}

export const PromptModal = ({ opened, onClose, onSubmit }: Props) => {
  const form = useForm({
    validate: {
      title: isNotEmpty("Title is required"),
      prompt: isNotEmpty("Prompt is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit(form.values as CreateAiPromptInput);
    handleClose();
  }, [form, handleClose, onSubmit]);

  return (
    <>
      <Modal
        opened={opened}
        title="Create Prompt"
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        size="xl"
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <Flex gap={12} direction="column">
            <TextInput
              required
              label="Title"
              placeholder="Enter Title"
              size="m"
              {...form.getInputProps("title")}
            />
            <Textarea
              placeholder="Enter Prompt"
              autosize
              size="m"
              minRows={3}
              {...form.getInputProps("prompt")}
            />
          </Flex>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              save
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
