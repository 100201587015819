import { useQuery } from "urql";

import {
  AiPromptFilter,
  AiPromptOrderBy,
  GetAiPromptsDocument,
} from "../../../../graphql/generated.ts";
import { ApiHookProps } from "../../types.ts";

export const useAIPrompts = ({
  filter,
  orderBy,
  pause,
}: ApiHookProps<AiPromptFilter, AiPromptOrderBy>) => {
  const variables = { filter, orderBy };
  return useQuery({ query: GetAiPromptsDocument, variables, pause });
};
